/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
/* CIRCE FONT START */
@font-face {
  font-family: 'Circe';
  src: url('../fonts/circe/Circe-ExtraLight.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/circe/Circe-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/circe/Circe-ExtraLight.woff') format('woff'),
    url('../fonts/circe/Circe-ExtraLight.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 100;
}

@font-face {
  font-family: 'Circe';
  src: url('../fonts/circe/Circe-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/circe/Circe-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/circe/Circe-Regular.woff') format('woff'),
    url('../fonts/circe/Circe-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Circe';
  src: url('../fonts/circe/Circe-Light.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/circe/Circe-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/circe/Circe-Light.woff') format('woff'),
    url('../fonts/circe/Circe-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 300;
}

@font-face {
  font-family: 'Circle';
  src: url('../fonts/circe/Circe-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/circe/Circe-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/circe/Circe-Bold.woff') format('woff'),
    url('../fonts/circe/Circe-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 700;
}

@font-face {
  font-family: 'Circle';
  src: url('../fonts/circe/Circe-ExtraBold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/circe/Circe-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/circe/Circe-ExtraBold.woff') format('woff'),
    url('../fonts/circe/Circe-ExtraBold.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 900;
}

@font-face {
  font-family: 'Circle';
  src: url('../fonts/circe/Circe-Thin.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/circe/Circe-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/circe/Circe-Thin.woff') format('woff'),
    url('../fonts/circe/Circe-Thin.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 200;
}

/* CIRCE FONT END */
/* ALEO FONT START */
@font-face {
  font-family: 'Aleo';
  src: url('../fonts/aleo/Aleo-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/aleo/Aleo-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/aleo/Aleo-Regular.woff') format('woff'),
    url('../fonts/aleo/Aleo-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Aleo';
  src: url('../fonts/aleo/Aleo-Light.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/aleo/Aleo-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/aleo/Aleo-Light.woff') format('woff'),
    url('../fonts/aleo/Aleo-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 300;
}

@font-face {
  font-family: 'Aleo';
  src: url('../fonts/aleo/Aleo-LightItalic.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/aleo/Aleo-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/aleo/Aleo-LightItalic.woff') format('woff'),
    url('../fonts/aleo/Aleo-LightItalic.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aleo';
  src: url('../fonts/aleo/Aleo-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/aleo/Aleo-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/aleo/Aleo-Bold.woff') format('woff'),
    url('../fonts/aleo/Aleo-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 700;
}

@font-face {
  font-family: 'Aleo';
  src: url('../fonts/aleo/Aleo-BoldItalic.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/aleo/Aleo-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/aleo/Aleo-BoldItalic.woff') format('woff'),
    url('../fonts/aleo/Aleo-BoldItalic.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 700;
  font-style: italic;
}

/* ALEO FONT END */
@font-face {
  font-family: 'CenturyGothic';
  src: url('../fonts/century-gothic/CenturyGothic.woff') format('woff'),
    url('../fonts/century-gothic/CenturyGothic.ttf') format('truetype'),
    url('../fonts/century-gothic/CenturyGothic.svg#CenturyGothic') format('svg');
  font-weight: normal;
  font-style: normal;
}

.pink {
  color: #fc1aff;
}

/* Clearface Font */
@font-face {
  font-family: 'Clearface';
  src: url('../fonts/clearface/ClearfaceITCPro-Black.ttf') format('truetype'),
    url('../fonts/clearface/ClearfaceITCPro-Black.woff') format('woff');
}

/* SofiaPro Font */
@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProLight.eot');
  src: local('Sofia Pro Light'), local('SofiaProLight'),
    url('../fonts/sofiapro/SofiaProLight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProLight.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProLight.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProLight-Italic.eot');
  src: local('Sofia Pro Light Italic'), local('SofiaProLight-Italic'),
    url('../fonts/sofiapro/SofiaProLight-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProLight-Italic.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProLight-Italic.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProLight-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProMedium-Italic.eot');
  src: local('Sofia Pro Medium Italic'), local('SofiaProMedium-Italic'),
    url('../fonts/sofiapro/SofiaProMedium-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProMedium-Italic.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProMedium-Italic.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProMedium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProMedium.eot');
  src: local('Sofia Pro Medium'), local('SofiaProMedium'),
    url('../fonts/sofiapro/SofiaProMedium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProMedium.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProMedium.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProBlack-Italic.eot');
  src: local('Sofia Pro Black Italic'), local('SofiaProBlack-Italic'),
    url('../fonts/sofiapro/SofiaProBlack-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProBlack-Italic.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProBlack-Italic.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProBlack-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProBlack.eot');
  src: local('Sofia Pro Black'), local('SofiaProBlack'),
    url('../fonts/sofiapro/SofiaProBlack.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProBlack.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProBlack.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProRegular-Italic.eot');
  src: local('Sofia Pro Regular Italic'), local('SofiaProRegular-Italic'),
    url('../fonts/sofiapro/SofiaProRegular-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProRegular-Italic.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProRegular-Italic.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProRegular-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofiapro/SofiaProRegular.eot');
  src: local('Sofia Pro Regular'), local('SofiaProRegular'),
    url('../fonts/sofiapro/SofiaProRegular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/sofiapro/SofiaProRegular.woff2') format('woff2'),
    url('../fonts/sofiapro/SofiaProRegular.woff') format('woff'),
    url('../fonts/sofiapro/SofiaProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Neon Glow */
@font-face {
  font-family: 'NEONGLOW';
  src: url('../fonts/neon_glow/neonglow.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sofia Pro', sans-serif;
  color: #39393b;
  font-size: 13px;
}

@media (min-width: 768px) {
  body {
    font-size: 12px;
  }
}

p {
  font-family: 'Circe', sans-serif;
  font-size: 14px;
}

@media (min-width: 768px) {
  p {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  p {
    font-size: 18px;
  }
}

h1 {
  font-size: 21px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 38px;
    margin-bottom: 30px;
  }
}

h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  h2 {
    font-size: 28px;
    margin-bottom: 24px;
  }
}

h4 {
  font-weight: 700;
  font-size: 16px;
}

.bg-cream {
  background-color: #f8f6f6;
}

.bg-cream--darker {
  background-color: #efeded;
}

.bg-gray {
  background-color: #e7e7e7;
}

.bg-lightgray {
  background-color: #f8f8f8;
}

.bg-darkblue {
  background-color: #c0d6cd;
}

.bg-beige {
  background-color: #bbb0a6;
}

.bg-pink {
  background-color: #fae1ff;
}

.bg-transparent {
  background: transparent;
}

.full-height {
  min-height: 100vh;
}

button,
.btn,
[role='button'] {
  cursor: pointer;
}

button:focus,
button:active,
button:hover,
.btn:focus,
.btn:active,
.btn:hover,
[role='button']:focus,
[role='button']:active,
[role='button']:hover {
  outline: 0;
}

.btn {
  border-radius: 100px;
  width: 120px;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}

.btn:disabled {
  opacity: 0.3;
}

@media (min-width: 768px) {
  .btn {
    font-size: 20px;
    width: 180px;
    padding: 20px 10px 18px;
  }
}

.btn-success {
  color: #111;
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

.btn-success:hover {
  color: #111;
  background-color: #ff0000;
  border-color: #ff0000;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #ff0000;
  border-color: #ff0000;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #ff0000;
  background-image: none;
  border-color: #ff0000;
}

.btn-success:focus,
.btn-success:hover {
  color: #fff;
}

.btn-close {
  width: 18px;
  height: 18px;
}

#btn-subscribe:hover {
  color: #fff;
}

.btn-outline-black {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000;
  border-width: 1px;
}

.btn-outline-black:hover {
  color: #faf5ee;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:focus,
.btn-outline-black.focus {
  box-shadow: 0 0 0 1px rgba(250, 245, 238, 0.5);
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: #222;
  background-color: transparent;
}

.btn-outline-black:active,
.btn-outline-black.active,
.show > .btn-outline-black.dropdown-toggle {
  color: #222;
  background-color: #222;
  border-color: #222;
}

/*.btn-outline-black:focus, .btn-outline-black:active, .btn-outline-black:hover {
  color: #faf5ee;
  background-color: #263a52;
}*/

.btn-inline-transparent {
  background-color: transparent;
  border-width: 1px;
}

.btn-inline-white {
  background-color: #fff;
  border-color: #fff;
  border-width: 1px;
}

.btn-inline-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
  border-width: 1px;
}

.btn-inline-black:hover {
  color: #000;
  border-color: #000;
}

.btn-inline-black:focus,
.btn-inline-black.focus {
  box-shadow: 0 0 0 1px rgba(250, 245, 238, 0.5);
}

.btn-inline-black.disabled,
.btn-inline-black:disabled {
  color: #222;
  background-color: transparent;
}

.btn-inline-black:active,
.btn-inline-black.active,
.show > .btn-inline-black.dropdown-toggle {
  color: #000;
  background-color: #000;
  border-color: #000;
}

.btn-inline-black:focus,
.btn-inline-black:active,
.btn-inline-black:hover {
  color: #faf5ee;
  background-color: #000;
}

.shopper-table .btn {
  font-family: 'Circe';
}

#btn-hero--submit {
  transition: 0.15s background-color;
  width: 250px;
  text-align: center;
  background-color: #97adff;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}

.zip-open #btn-hero--submit:after {
  content: attr(data-submit);
}

#btn-hero--submit.is-loading:after {
  content: 'Loading...';
}

#submit-address {
  transition: 0.15s background-color;
}

#submit-address:after {
  content: 'Send Link';
}

#submit-address.is-loading:after {
  content: 'Loading...';
}

#submit-phone {
  transition: 0.15s background-color;
}

#submit-phone:after {
  content: 'Send Link';
}

#submit-phone.is-loading:after {
  content: 'Loading...';
}

#submit-email {
  transition: 0.15s background-color;
}

#submit-email:after {
  content: 'Notify Me';
}

#submit-email.is-loading:after {
  content: 'Loading...';
}

.form-control {
  background-color: #ffffff;
  font-size: 12px;
  height: 32px;
  border-width: 2px;
}

@media (min-width: 768px) {
  .form-control {
    height: 50px;
    font-size: 18px;
  }
}

.aw-header {
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
  transition: 0.27s all;
}

.aw-absolute {
  position: absolute;
}

.aw-align-self-center {
  align-self: center;
}

.util-font-size-13 {
  font-size: 13px;
}

.util-font-size-14 {
  font-size: 14px;
}

.util-font-size-16 {
  font-size: 16px;
}

.util-relative {
  position: relative;
}

.util-offset-top--30 {
  top: -30px;
}

.util-bg-color-white {
  background-color: #fff !important;
}

.util-padding-20 {
  padding: 20px;
}

.util-margin-top-20 {
  margin-top: 20px;
}

.aw-header .btn-login,
.aw-header .btn-sign-up,
.aw-header .btn-backers {
  font-weight: bold;
  vertical-align: text-bottom;
}

@media (min-width: 768px) {
  .aw-header .btn-get--started {
    font-size: 17px;
    width: 130px;
    padding: 11px;
  }

  .aw-header .btn-login,
  .aw-header .btn-sign-up,
  .aw-header .btn-backers {
    font-size: 13px;
    width: 105px;
    padding: 6.5px;
  }
  .aw-header .btn-backers {
    width: 30px;
  }
}

@media (min-width: 768px) {
  .aw-header {
    padding: 18px 0;
    box-shadow: none;
  }
}

.aw-logo--fill {
  fill: #fc8562;
}

.aw-logo--shape {
  transition: 0.27s all;
}

.aw-logo svg {
  width: 123px;
  height: 40px;
}

.aw-logo--text {
  stroke-width: 10;
  stroke: #fc8663;
  fill: #fc8663;
}

.aw-nav--toggle {
  width: 48px;
  height: 48px;
  padding: 0;
  position: absolute;
  right: -10px;
  top: 43%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: transparent;
}

.aw-nav--toggle svg {
  max-width: 18px;
}

.aw-nav--toggle svg path {
  stroke-width: 2;
  stroke: #222222;
  transition: 0.27s stroke;
}

.aw-nav--mobile {
  font-weight: 700;
  z-index: 2000;
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  background-color: #fff;
  color: #263a52;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  transition: 0.27s all;
}
.top-bar-open .aw-nav--mobile {
  top: 100px;
}
.aw-nav--mobile ul {
  list-style-type: none;
}

.aw-nav--mobile li a {
  text-decoration: none;
  display: inline-block;
  padding: 10px 10px;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
}

@media (min-width: 350px) {
  .aw-nav--mobile li a {
    padding: 15px 10px;
  }
}

@media (min-width: 480px) {
  .aw-nav--mobile li a {
    font-size: 1.25rem;
  }
}

@media (min-width: 640px) {
  .aw-nav--mobile li a {
    font-size: 1.5rem;
  }
}

.aw-nav--mobile li a:focus,
.aw-nav--mobile li a:active,
.aw-nav--mobile li a:hover {
  color: #ccc;
}

@media (min-width: 991px) {
  .aw-nav--mobile li {
    display: inline-block;
    vertical-align: middle;
  }
}

.aw-nav--mobile .btn {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.aw-nav--mobile .btn:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.aw-nav--mobile .btn:focus,
.aw-nav--mobile .btn.focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.aw-nav--mobile .btn.disabled,
.aw-nav--mobile .btn:disabled {
  color: #fff;
  background-color: transparent;
}

.aw-nav--mobile .btn:active,
.aw-nav--mobile .btn.active,
.show > .aw-nav--mobile .btn.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.aw-nav--mobile .btn:focus,
.aw-nav--mobile .btn:active,
.aw-nav--mobile .btn:hover {
  color: #111;
  background-color: #ffffff;
  border-color: #303a88;
  color: #303a88 !important;
}

.aw-nav--mobile .btn:focus:hover,
.aw-nav--mobile .btn:active:hover,
.aw-nav--mobile .btn:hover:hover {
  color: #111;
  background-color: #ececec;
  border-color: #232a62;
}

.aw-nav--mobile .btn:focus:focus,
.aw-nav--mobile .btn:focus.focus,
.aw-nav--mobile .btn:active:focus,
.aw-nav--mobile .btn:active.focus,
.aw-nav--mobile .btn:hover:focus,
.aw-nav--mobile .btn:hover.focus {
  box-shadow: 0 0 0 3px rgba(48, 58, 136, 0.5);
}

.aw-nav--mobile .btn:focus.disabled,
.aw-nav--mobile .btn:focus:disabled,
.aw-nav--mobile .btn:active.disabled,
.aw-nav--mobile .btn:active:disabled,
.aw-nav--mobile .btn:hover.disabled,
.aw-nav--mobile .btn:hover:disabled {
  background-color: #ffffff;
  border-color: #303a88;
}

.aw-nav--mobile .btn:focus:active,
.aw-nav--mobile .btn:focus.active,
.show > .aw-nav--mobile .btn:focus.dropdown-toggle,
.aw-nav--mobile .btn:active:active,
.aw-nav--mobile .btn:active.active,
.show > .aw-nav--mobile .btn:active.dropdown-toggle,
.aw-nav--mobile .btn:hover:active,
.aw-nav--mobile .btn:hover.active,
.show > .aw-nav--mobile .btn:hover.dropdown-toggle {
  background-color: #ececec;
  background-image: none;
  border-color: #232a62;
}

.aw-nav--mobile .btn-close {
  background-color: transparent;
  border: none;
}

.aw-nav--top {
  padding: 10px 0;
}

.aw-nav--middle {
  margin: 20px 0;
}

.aw-nav {
  font-size: 16px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .aw-nav {
    font-size: 17px;
  }
}

.aw-nav ul {
  list-style-type: none;
}

.aw-nav li {
  display: inline-block;
  vertical-align: middle;
}

.aw-nav li + li {
  margin-left: 30px;
}

.aw-nav a {
  color: #000;
  font-weight: normal;
}

.aw-nav a:focus,
.aw-nav a:active,
.aw-nav a:hover {
  color: rgba(34, 34, 34, 0.75);
  text-decoration: none;
}

.aw-nav--link {
  position: relative;
}

.aw-nav--link.active:after {
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #222;
  display: block;
}

.nav-open {
  overflow: hidden;
}

.nav-open .aw-header {
  background-color: #303a88;
}

.nav-open .aw-nav--mobile {
  visibility: visible;
  opacity: 1;
  overflow-y: auto;
}

.nav-open .aw-logo--fill {
  fill: #ffffff;
}

.nav-open .aw-logo--shape {
  fill: #303a88;
}

.nav-open .aw-logo--text {
  fill: #ffffff;
  stroke: #ffffff;
}

.nav-open .aw-nav--toggle svg path {
  stroke: #ffffff;
}

.nav-open + .aw-main {
  display: none;
}

body {
  position: relative;
}

.container-fluid {
  max-width: 1280px;
  padding-left: 30px;
  padding-right: 30px;
}

.container-fluid.full-width {
  max-width: none;
}

.page-section {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.page-section:nth-child(1) {
  padding-top: 105px;
}

@media (min-width: 768px) {
  .page-section:nth-child(1) .row {
    min-height: 480px;
  }
}

@media (min-width: 992px) {
  .page-section:nth-child(1) {
    padding-top: 100px;
  }
  .page-section:nth-child(1) .row {
    min-height: 768px;
  }
}

.page-section:nth-child(1) .container-fluid > .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 992px) {
  .page-section {
    padding-top: 100px;
    padding-bottom: 40px;
  }
}

.aw-process .row {
  min-height: 1000px;
}

.aw-process h2 span {
  font-weight: 400;
  color: #b11515;
  display: block;
  margin-top: 5px;
}

.aw-process--halves {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.aw-process--title {
  position: relative;
  z-index: 1;
}

.aw-list--checked {
  font-family: 'Aleo', sans-serif;
  list-style-type: none;
  line-height: 2;
}

.aw-reinvented {
  padding: 75px 0;
}

.aw-reinvented .container-fluid {
  position: relative;
}

.svg-wrap {
  position: relative;
  z-index: 100;
}

.svg-wrap svg {
  width: 270px;
  height: auto;
  max-width: 100%;
  display: block;
}

.bottle-left--top {
  top: -50%;
  position: absolute;
  max-width: calc(100% - 30px);
  max-height: 360px;
}

.bottle-left--bottom {
  bottom: -50%;
  position: absolute;
  max-width: calc(100% - 30px);
  max-height: 320px;
}

.bottle-right {
  position: relative;
  bottom: -150px;
  max-height: 480px;
}

.aw-hero .container-fluid > .row {
  min-height: 480px;
}

@media (min-width: 768px) {
  .aw-hero .container-fluid > .row {
    min-height: 560px;
  }
}

.aw-hero h1 {
  color: #4940db;
  margin-bottom: 25px;
}

.aw-hero a {
  color: #fc1aff;
}

.aw-hero-construct h1 {
  color: #4940db;

  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .aw-hero h1 {
    margin-bottom: 35px;
  }
}

.aw-hero .form-control {
  text-align: center;
  width: 130px;
}

@media (min-width: 768px) {
  .aw-hero .form-control {
    width: 210px;
  }
}

.aw-hero--form {
  margin-top: 2rem;
}

.aw-hero--form .form-group {
  margin-bottom: 28px;
}

@media (min-width: 768px) {
  .aw-hero--form .form-group {
    margin-bottom: 35px;
  }
}

.aw-hero--form .input_group {
  display: none;
}

.zip-open .aw-hero--form .input_group {
  display: block;
}

.aw-grid--item {
  text-align: center;
  padding: 40px 30px;
  height: 100%;
  min-height: 230px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .aw-grid--item {
    text-align: left;
    min-height: 290px;
  }
}

.aw-grid--item h2 {
  margin-bottom: 15px;
}

.aw-grid--item p {
  margin-bottom: 0;
}

.aw-grid--image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.aw-iconbox--description {
  font-size: 15px;
}

.aw-iconbox--round {
  width: 112px;
  height: 85px;
  border-radius: 10em;
  margin-bottom: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .aw-iconbox--round {
    width: 112px;
    height: 70px;
    margin-bottom: 30px;
  }
}

.aw-ourstory--action {
  margin-top: 70px;
}

.aw-footer {
  padding: 30px 0;
  color: #263a52;
  line-height: 2;
  position: relative;
}

@media (min-width: 768px) {
  .aw-footer {
    padding: 60px 0;
  }
}

.aw-footer a {
  color: #263a52;
}

.aw-footer ul {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
}

.aw-footer ul.aw-social {
  line-height: 1;
}

@media (min-width: 768px) {
  .aw-footer ul {
    margin-top: 30px;
  }
}

.aw-footer h4 {
  letter-spacing: 1.5px;
  margin-bottom: 0;
  padding-bottom: 12px;
  position: relative;
  display: inline-block;
  font-size: 12px;
}

@media (min-width: 768px) {
  .aw-footer h4 {
    font-size: 16px;
  }
}

.aw-footer h4:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #263a52;
}

#invalid-text {
  color: #ff0000;
}

#server-error {
  color: #ff0000;
}

.header-wrap + .aw-main {
  padding-top: 80px;
}

.header-wrap.top-bar-open + .aw-main .product-top.fixed {
  top: 0px;
}

.header-wrap.top-bar-open + .aw-main {
  padding-top: 181px;
}

.admin-tabs .active-tab {
  font-weight: bold;
  background-color: #ececec;
}

.aw--custom-dropdown .dropdown-toggle,
.shopper-table .btn,
.fulfillment-page .btn,
.delivery-page .btn {
  width: auto;
  padding: 15px 10px;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .aw--custom-dropdown .dropdown-toggle,
  .shopper-table .btn,
  .fulfillment-page .btn,
  .delivery-page .btn {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .shopper-table .form-control {
    padding: 0.1rem;
  }
}

.shopper-table .row {
  cursor: pointer;
}

.shopper-table .row:hover {
  background: #e8e8e8;
}

.shopper-table .completed,
.shopping-app-table .pending,
.shopping-app-table .not-checked {
  background: lightgrey;
}

.shopper-table .completed:hover {
  background: #bebebe;
}

.shopper-table .missing,
.shopping-app-table .unavailable {
  background: lightcoral;
}
/* step 3 view CSS */
.btn-sm {
  padding: 0.35rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  width: 100px;
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-sm.active {
  background-color: #78c98d;
  border-color: #78c98d;
  border: none;
}

.add-button {
  height: 50px;
  width: 80px;
  border: none;
  font-size: 12px;
  margin: 10px;
}

.selected {
  border: 1px solid rgba(0, 217, 255, 0.171);
  box-shadow: 2px 3px 4px rgba(0, 0, 255, 0.336);
}

.switchButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.prev-img {
  max-height: 45px;
  cursor: pointer;
  /* width: 80px; */
}

.prev-img:hover {
  opacity: 0.8;
}

.temp-img {
  max-height: 90%;
  max-width: 90%;
}

.img-preview {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.msg-pop-up {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  height: 200px;
  width: 240px;
  background: rgb(8, 8, 58);
  font-size: 20px;
  border-radius: 30px;
  color: white;
}

@media all and (max-width: 900px) {
  input {
    max-width: 100%;
  }
  .clear-img-btn {
    font-size: 10px;
  }
}

/* .step3-btn-spacing{
  display: flex;
  justify-content: center;
} */
/* end of step 3 view css */
.shopper-table .purchased {
  background: #00800070;
}
.shopper-table .purchased:hover {
  background: #0080008f;
}

.shopping-app-table .available,
.shopping-app-table .checked {
  background: #78c98d;
}

.shopping-app-table label,
input {
  cursor: pointer;
}

.modal-missing .modal-header {
  justify-content: flex-start;
}

.modal-missing table {
  padding: 5px;
}

.btn-center {
  display: flex;
  justify-content: center;
}

.btns-space-btw {
  display: flex;
  justify-content: space-between;
}

.packaging-table .missing:hover {
  background: #c76464;
}

.packaging-table .row {
  cursor: pointer;
}

.packaging-table .row:hover {
  background: #e8e8e8;
}

.packaging-table .delivered,
.paid {
  background: #008400;
}

.packaging-table .paid:hover {
  background: #bebebe;
}
.packaging-table .payment_issue {
  background: lightcoral;
}

.packaging-table .payment_issue:hover {
  background: #c76464;
}

.packaging-table .delivery_issues {
  background: lightcoral;
}

.packaging-table .delivery_issue:hover {
  background: #c76464;
}

.order-table .packaged {
  background: #8bf18b;
}

.order-table .order-row {
  background: #908e8e;
}

.user-header {
  font-size: 20px;
  float: right;
}

.delivery-table .row {
  cursor: pointer;
}

.delivery-table .row:hover {
  background: #e8e8e8;
}

.delivery-table .delivered {
  background: lightgrey;
}
.delivery-table .delivered:hover {
  background: #bebebe;
}
.delivery-table .delivery_issue {
  background: lightcoral;
}

.delivery-table .delivery_issue:hover {
  background: #c76464;
}

.modal-shopper .aw--custom-dropdown {
  margin-bottom: 100px;
}
.xl-modal {
  max-width: 90%;
  width: 90%;
  font-style: 16px;
}
.xl-modal .modal-header {
  border-bottom: 1px solid #e9ecef;
}
.xl-modal .modal-footer {
  border-top: 1px solid #e9ecef;
  justify-content: space-between;
}
.shopper-location {
  padding: 5px 10px;
  cursor: pointer;
}

.shopper-location:hover {
  background: #ececec;
}

.scrollable-table ~ .btn {
  margin-top: 20px;
  max-width: 120px;
}

.fulfillment-page .nav-tabs .nav-link {
  font-weight: bold;
}

.pack-order-details {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.pack-order-details > div:not(:last-child) {
  border-right: 1px solid #000;
}

.custom-datepicker {
  height: calc(2.25rem + 2px);
}

.edit-blog-post {
  border-radius: 0;
  width: auto;
}

.post-edtior-page textarea {
  height: 300px;
}

.single-product .row {
  align-items: center;
  font-size: 18px;
}
.single-product .row strong {
  font-size: 16px;
}
.single-product .nav-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-product .input-group-text {
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.scrollable-table {
  overflow-x: auto;
}

.single-order-total {
  margin: 20px auto;
}

.single-order .nav-buttons {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}

.single-order-modal .modal-footer,
.missing-modal .modal-footer {
  justify-content: flex-end !important;
}

.single-order-modal .modal-footer,
.missing-modal .modal-footer {
  font-size: 16px;
  max-height: unset;
}

.missing-modal .modal-body {
  max-height: unset;
  overflow: unset;
}
.missing-modal .modal-body p {
  font-size: 16px;
  margin-top: 10px;
}

.cart-item-change {
  background: #f5bc53;
}

.price-item-change {
  background: #f76c6c;
}
.input-group {
  flex-wrap: unset;
}
.hide {
  display: none;
}

.packaging-quantity {
  font-size: 10px;
  width: 50%;
}
