.dotGroup button {
  -webkit-appearance: none;
  margin: 0 0.25rem;
  width: 1rem;
  height: 1rem;
  border: none;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
}

.dotGroup button:disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
