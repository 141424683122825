@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
/* Global */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
body {
  padding: 0;
  font-family: 'Sofia Pro', sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}
h1 {
  font-family: 'Clearface';
}
.btn-outline-white {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
}
.btn-outline-white:hover,
.btn-outline-white:focus {
  background-color: #fff;
  color: #323f93;
}
.btn-success {
  border: 0;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 30px;
  font-weight: 700;
}
.btn-wauto {
  width: auto;
}

.backdrop-wrap {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.backdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
}

.backdrop.white {
  background-color: rgba(255, 255, 255, 0);
}

.aw-main .backdrop.hide {
  display: none;
}
.aw-main.aw-home {
  min-height: 600px;
}
.page-section {
  padding-top: 45px;
  padding-bottom: 50px;
}
.page-section h2 {
  padding-bottom: 10px;
  margin-bottom: 0;
}
.page-section[class*='aw-account-'] h2 {
  border-bottom: 1px solid #cecece;
}
h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}
h3.fz28 {
  font-size: 28px;
}
p {
  font-size: 19px;
}
.page-title {
  font-size: 38px;
}
.page-title.fz28 {
  font-size: 28px;
}

.card {
  border: 0;
  box-shadow: 0 1px 7px 0 rgba(226, 226, 226, 0.5);
  -webkit-box-shadow: 0 1px 7px 0 rgba(226, 226, 226, 0.5);
  border-radius: 0;
}

.page-link {
  line-height: 24px;
  color: #6e8692;
  border: 0;
  white-space: nowrap;
}
.page-link i {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}
.page-link:focus,
.page-link:hover,
.page-item.active .page-link {
  color: #45bbc9;
  background-color: transparent;
}

.center-middle {
  width: 100%;
  text-align: center;
}

/* Header */
.aw-header {
  padding-top: 0px;
  padding-bottom: 10px;
  font-family: 'Sofia Pro';
}
.aw-header .container {
  padding-top: 10px;
}
.aw-logo {
  height: 50px;
}
.aw-logo svg {
  width: auto;
  height: 50px;
}
.aw-header:not(.navbar-white) .cls-1 {
  fill: #fae1ff;
}
.aw-header:not(.navbar-white) nav.aw-nav a {
  color: #fff;
}
.aw-header:not(.navbar-white) .aw-logo--shape {
  fill: #323f93;
}
.aw-header:not(.navbar-white) .btn-outline-black {
  border-color: #fff;
  color: #fff;
}
.aw-header:not(.navbar-white) .btn-outline-black:hover,
.aw-header:not(.navbar-white) .btn-outline-black:focus {
  background-color: #fff;
  color: #323f93;
}
.aw-header,
.aw-header.navbar-white {
  background-color: transparent;
}

.navbar-toggler {
  display: flex;
}

.aw-header.navbar-white .navbar-toggler-icon {
  background-image: url('../images/hamburger.png');
  max-width: 23px;
  margin-top: 7px;
  margin-right: 7px;
}

.nav-open .aw-header.navbar-white .navbar-toggler-icon {
  background-image: url('../images/close.svg');
  max-width: 30px;
}

.aw-header .mobile-top-nav {
  position: relative;
  justify-content: flex-end;
}

.top-nav {
  height: 63px;
}

.aw-nav {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.aw-nav .dropdown-item {
  text-transform: none;
}

body.nav-open:not(.page-inner) .aw-header:not(.navbar-white) {
  background-color: #303a88;
}

nav.aw-nav,
.aw-header .btn-get--started {
  font-size: 13px;
}
.aw-header .btn-get--started {
  font-family: 'Sofia Pro', sans-serif;
}
nav.aw-nav a {
  position: relative;
}
nav.aw-nav a:focus,
nav.aw-nav a:active,
nav.aw-nav a:hover {
  color: #222;
}
nav.aw-nav a::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: -5px;
  border-bottom: 2px solid transparent;
}
nav.aw-nav a:focus::after,
nav.aw-nav a:active::after,
nav.aw-nav a:hover::after {
  border-bottom-color: #fff;
}
body.page-inner nav.aw-nav a:focus::after,
body.page-inner nav.aw-nav a:active::after,
body.page-inner nav.aw-nav a:hover::after,
.navbar-white nav.aw-nav a:focus::after,
.navbar-white nav.aw-nav a:active::after,
.navbar-white nav.aw-nav a:hover::after {
  border-bottom-color: #000;
  border-bottom: 0.6px solid #000;
}

nav.aw-nav a.dropdown-item:hover::after {
  border-bottom: none;
}

.landing-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  padding: 100px 0 0 0;
}
.landing-section h1 {
  color: rgb(0, 0, 0);
  font-size: 45px;
  font-family: 'Clearface';
}
.landing-section h2 {
  font-size: 20px;
  line-height: 32px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}
.landing-section h2.left {
  text-align: left;
}
.landing-section h5 {
  font-size: 16px;
  color: rgb(0, 0, 0);
}
.aw-hero input.zip {
  border: none;
  text-align: left;
  margin: 15px auto;
  width: 100%;
  height: 61px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #000;
  border-radius: 0;
}

.landing-section img {
  height: 135px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

.aw-hero .container-fluid > .row {
  height: calc(60% - 70px);
}
.aw-header .btn-top-account {
  padding-left: 0;
}
.aw-header .btn-top-account button {
  margin: 0 10px;
}

.welcome-section {
  background-color: #faf5ee;
  padding: 5rem 0;
}
.welcome-section.dark-blue {
  background-color: #263a52;
  color: #fff;
}
.welcome-section h2 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.2rem;
}
.homepage-background {
  background-image: linear-gradient(#fae1ff, #fff);
}

.homepage-info-section {
  background-color: #bbb0a6;
  color: #fff;
  overflow: hidden;
}
.homepage-info-section.alt-bg {
  background-color: #ebe9e4;
}
.homepage-info-section.third-bg {
  background-color: #898f87;
}
.homepage-info-section .col-sm-6 {
  padding: 0;
}
.text-info-section {
  padding: 10rem 4rem;
  max-width: 600px;
}
.text-info-section.force-black {
  color: #000;
}

.subscribe-control .custom-control {
  display: inline;
}
.subscribe-control .custom-control-label::before {
  background: white !important;
}
.subscribe-control .custom-control-label {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .receive-item {
    float: right;
    text-align: right;
  }
}

.howto-item {
  text-align: center;
  margin-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
.howto-item img,
.howto-item h4 {
  font-size: 16px;
  margin: 20px auto;
  text-align: center;
}
.howto-item p {
  font-size: 15px;
}

.howto-item img {
  height: auto;
  max-width: 90%;
  width: 90%;
}
.howto-item h4 {
  text-align: left;
}

.tagline {
  text-align: center;
  max-width: 670px;
  margin: 0 auto 70px;
}
.tagline h2 {
  font-size: 28px;
}
.tagline p {
  font-size: 20px;
  text-align: justify;
  line-height: 30px;
}

.bg-card {
  background-color: #fff;
  margin-bottom: 40px;
  box-shadow: 0 1px 7px 0 rgba(226, 226, 226, 0.5);
  -webkit-box-shadow: 0 1px 7px 0 rgba(226, 226, 226, 0.5);
}
.bg-card .col-md-6 {
  overflow: hidden;
}
.aw-grid--item.lg {
  padding-left: 70px;
  padding-right: 70px;
}
.story-item h3 {
  font-size: 26px;
}
.story-item p {
  font-size: 17px;
}

.aw-footer {
  text-align: left;
  padding-bottom: 0;
}
/* .aw-footer .container{max-width:800px;} */
.aw-footer h4 {
  font-size: 14px;
}
.aw-footer a {
  font-size: 15px;
}
.aw-footer li {
  font-size: 15px;
}
.footer-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
}
.footer-bottom .form-inline {
  max-width: 600px;
  margin: 0 auto;
}
.footer-bottom .form-inline .input-group {
  width: 290px;
}
.footer-bottom label {
  margin-right: 20px;
}
.footer-bottom .form-control {
  border-width: 1px;
  height: 34px;
  font-size: 14px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.footer-bottom .btn {
  font-size: 14px;
  line-height: 20px;
  border: 0;
  padding: 7px 15px;
  border-radius: 0 4px 4px 0;
  width: auto;
  background-color: #97adff;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  color: #fff;
}
.footer-bottom label {
  font-size: 14px;
}
.footer-logo {
  width: 60px;
}

/* Our Story */
.page-inner {
  padding-top: 100px;
}
.section-inner {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* How It Works */
.page-lead {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 70px;
}
.page-lead h2 {
  font-size: 28px;
}
.page-lead p {
  font-size: 18px;
  line-height: 1.6;
}
.process-item {
  padding: 50px;
}
.col-6 + .col-6 .process-item::before {
  position: absolute;
  content: '';
  top: 50px;
  bottom: 60px;
  border-left: 1px solid #979797;
  left: 0;
}
.process-item h3 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 50px;
  font-size: 24px;
}
.process-item p {
  font-size: 15px;
}

.step {
  position: absolute;
  width: 160px;
}
.op-step1 {
  left: 0;
  top: 40px;
}
.op-step2 {
  right: 0;
  top: 200px;
}
.op-step3 {
  left: 0;
  top: 360px;
}
.op-step4 {
  right: 0;
  bottom: -10px;
}

.cp-step1 {
  left: 0;
  top: 0;
}
.cp-step2 {
  right: -20px;
  top: 195px;
}
.cp-step3 {
  left: 0;
  top: 350px;
  width: 140px;
}
.cp-step4 {
  right: 0;
  top: 440px;
  width: 140px;
}
.cp-step5 {
  left: 0;
  top: 580px;
}
.cp-step6 {
  left: 0;
  bottom: -10px;
  width: 235px;
}
.process-item img {
  display: block;
  max-width: 100%;
}

/* why it matters */
.whyitmatters-item {
  text-align: center;
  margin-bottom: 30px;
}
.whyitmatters-item .card-body {
  padding-left: 70px;
  padding-right: 70px;
}
.whyitmatters-item h3 {
  font-size: 23px;
  font-weight: 700;
  font-family: 'Sofia Pro', sans-serif;
  margin-bottom: 25px;
}
.whyitmatters-item p {
  font-size: 17px;
}

/* Faq */
.faq-accordion {
  max-width: 690px;
  margin: 0 auto;
}
.faq-accordion .card {
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 1px solid #ecebeb;
}
.faq-accordion .card:last-child {
  border-bottom: 0;
}
.faq-accordion .card-header {
  padding: 0;
  background-color: transparent;
  border-bottom: 0;
}
.faq-accordion .btn {
  width: 100%;
  text-align: left;
  color: #39393b;
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding: 15px 20px 15px 0;
  font-size: 19px;
  position: relative;
  white-space: normal;
  line-height: 1.4;
}
.faq-accordion .btn::after {
  position: absolute;
  content: '\e904';
  font-family: 'icomoon' !important;
  color: #45bbc9;
  right: 0;
  top: 15px;
  font-size: 20px;
}
.faq-accordion .btn.collapsed {
  font-weight: 400;
}
.faq-accordion .btn.collapsed::after {
  content: '\e900';
}
.faq-accordion .card-body {
  font-family: 'Aleo', sans-serif;
  padding-left: 0;
  padding-top: 0;
  font-size: 15px;
}

/* Blog */
.section-blog .page-lead {
  max-width: 560px;
  margin-bottom: 0;
}
.section-blog-content {
  padding-top: 45px;
  padding-bottom: 30px;
}
.blog-item {
  border-bottom: 2px solid #ebebeb;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.blog-img > img {
  display: block;
  max-width: 100%;
}
.blog-title {
  font-size: 25px;
  font-weight: 700;
  font-family: 'Sofia Pro', sans-serif;
  line-height: 1.4;
  margin-bottom: 15px;
}

.blog-collapse {
  display: none;
}
.blog-pagination {
  padding-top: 20px;
}
.pagination .page-link {
  font-weight: 700;
}
.blog-list {
  font-family: 'Aleo', sans-serif;
  font-size: 18px;
}

/* Partners */
.section-partners .page-lead {
  max-width: 520px;
}
.aw-howto .page-lead {
  max-width: 440px;
}
.partners-left {
  padding: 50px 60px;
}
.partners-left h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
}
.partners-right {
  background-color: #e9e9e9;
  height: 100%;
}
.partners-right img {
  width: 100%;
  height: 100%;
}
.list-check {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}
.list-check > li {
  padding-left: 25px;
  position: relative;
  font-family: 'Aleo', sans-serif;
  margin-bottom: 10px;
  font-size: 17px;
}
.list-check > li::before {
  position: absolute;
  content: '\e906';
  font-family: 'icomoon' !important;
  color: #303a88;
  font-size: 16px;
  left: 0;
  top: 0;
}
.partners-content .col-md-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 53.77358%;
  flex: 0 0 53.77358%;
  max-width: 53.77358%;
}
.partners-content .col-md-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 46.22641%;
  flex: 0 0 46.22641%;
  max-width: 46.22641%;
}
.partners-valid,
.partners-invalid {
  display: none;
}
.partners-valid {
  max-width: 330px;
}
.partners-invalid {
  max-width: 300px;
}
.partners-valid p,
.partners-invalid p {
  margin-bottom: 25px;
  font-size: 17px;
}
#submit-address:after {
  content: "Let's go";
}

/* Get Notified */
.get-notified-form {
  max-width: 380px;
  margin-right: 160px;
  margin-left: auto;
}
#submit-email::after {
  content: 'Notify me';
}

/* Pricing */
.section-pricing .page-lead {
  max-width: 580px;
}
.card-pricing img {
  height: 90px;
}
.card-pricing h3 {
  margin-bottom: 0;
  font-size: 20px;
}
.plan-desc {
  color: #979797;
  font-family: 'Aleo', sans-serif;
  font-size: 13px;
}
.pricing-head {
  padding-left: 15px;
  padding-right: 15px;
}
.pricing-features {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}
.pricing-features > li {
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
}
.pricing-features > li:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}
.pricing-features > li .pricing-features-details {
  font-family: 'Aleo', sans-serif;
  font-size: 13px;
  font-weight: 500;
}
.pricing-features > li .pricing-features-details svg {
  display: none;
}

/* Materials */
.section-materials .page-lead {
  max-width: 640px;
}
.page-recyclestep h3 {
  font-size: 20px;
  min-height: 44px;
}
.page-recyclestep .media.mt1 {
  margin-top: 60px;
}
.page-recyclestep .media.mt2 {
  margin-top: 40px;
}
.table-recycleimg {
  width: 291px;
}
.table.table-recycleimg th,
.table.table-recycleimg td {
  border-top: 0;
  text-align: center;
  padding: 0;
}
.table.table-recycleimg td.td-text {
  width: 33.3333%;
  vertical-align: middle;
}
.col-20 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.material-img {
  text-align: center;
  margin-bottom: 20px;
}
.material-img h4 {
  margin-bottom: 0;
  opacity: 0;
}
.material-img:hover h4 {
  opacity: 1;
}
.materials-tabnav {
  margin-bottom: 10px;
  position: relative;
}
.materials-tabnav .nav-tabs {
  border: 0;
}
.materials-tabnav .nav-tabs .nav-item {
  margin-bottom: 0;
}
.materials-tabnav .nav-tabs .nav-link {
  color: #000;
  border: 0 !important;
  font-size: 18px;
  position: relative;
}
.materials-tabnav .nav-tabs .nav-help {
  margin-left: 140px;
}
.materials-tabnav .nav-tabs .nav-help .nav-link {
  font-size: 28px;
  padding: 0;
}
.materials-tabnav .nav-tabs .nav-help .nav-link i {
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;
}
.materials-tabnav .nav-tabs .nav-link.active,
.materials-tabnav .nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
}
.materials-tabnav .nav-tabs .nav-link.active::before,
.materials-tabnav .nav-tabs .nav-item.show .nav-link::before {
  position: absolute;
  content: '';
  height: 2px;
  background-color: #000;
  bottom: 4px;
  left: 10px;
  right: 10px;
}
.materials-tabnav .nav-tabs .nav-help .nav-link::before {
  display: none;
}
.materials-tabnav .nav-tabs .nav-help .nav-link.active i:before {
  content: '\e909';
}
.right-tab {
  position: absolute;
  right: 0;
  top: 0;
}
#help .media img {
  width: 80px;
}

/* About */
.full-img {
  height: 326px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 326px;
}

/* Shop */
.shop-section {
  padding-top: 30px;
}
.heading-line {
  border-bottom: 1px solid #d1d1d1;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
}
.heading-line::after {
  position: absolute;
  content: '';
  width: 100px;
  border-top: 2px solid #39393b;
  left: 0;
  bottom: -1px;
}
.shop-wrap {
  margin: 0 -30px 20px;
}
.shop-item {
  display: block;
  text-decoration: none !important;
  position: relative;
  margin: 0 30px 30px;
  color: #39393b !important;
}
.shop-item img {
  display: block;
  width: 100%;
  max-width: 100%;
}
.shop-item h3 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 2;
  font-size: 26px;
  padding: 10px 20px;
}

.shop-search {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 40px;
}
.shop-search .form-control {
  border: 0;
}
.input-group-append,
.input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
}
.input-group-text i {
  margin-top: -4px;
}

@media (max-width: 1199px) {
  .howto-item h4,
  .howto-item {
    padding-left: 0;
    padding-right: 0;
  }
  .aw-grid--item.lg {
    padding-left: 50px;
    padding-right: 50px;
  }
  .current-process {
    width: 365px;
    height: 660px;
  }
  .cp-step2 {
    top: 185px;
  }
  .cp-step3 {
    top: 320px;
  }
  .cp-step4 {
    top: 400px;
  }
  .cp-step5 {
    top: 525px;
  }
  .our-process {
    width: 365px;
    height: 613px;
  }
  .op-step2 {
    right: -20px;
    top: 180px;
  }
  .op-step3 {
    top: 320px;
  }
  .op-step4 {
    right: -20px;
  }
  .whyitmatters-item .card-body {
    padding-left: 40px;
    padding-right: 40px;
  }
  .get-notified-form {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .top-nav {
    height: 40px;
  }
  .page-inner {
    padding-top: 60px;
  }
  /*.logo-text{height:30px;}*/
  .aw-header {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .aw-header .container {
    padding-top: 10px;
  }
  body.page-inner .aw-header,
  body.page-inner .aw-header.navbar-white,
  .nav-open .aw-header.navbar-white {
    background-color: #ffffff;
  }
  body.page-inner .aw-header .cls-1,
  .nav-open .aw-header .cls-1 {
    fill: #fff;
  }
  .aw-footer .container {
    max-width: 540px;
  }
  .story-content {
    margin-left: 200px;
  }
  .our-process,
  .current-process {
    width: auto;
    height: auto;
    background: none;
    padding-top: 0;
  }
  .op-step1,
  .op-step2,
  .op-step3,
  .op-step4,
  .cp-step1,
  .cp-step2,
  .cp-step3,
  .cp-step4,
  .cp-step5,
  .cp-step6 {
    position: relative;
    width: auto;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }
  .step {
    text-align: center;
  }
  .whyitmatters-item .card-body {
    padding-left: 40px;
    padding-right: 40px;
  }
  .whyitmatters-item h3 {
    font-size: 20px;
  }
  .section-path1,
  .section-letsgetstarted {
    padding-top: 50px;
  }
  .img-phone {
    margin: 0 auto;
  }
  .get-notified-form {
    margin-right: 0;
    max-width: 340px;
  }
  .story-item h3 {
    font-size: 24px;
  }
  .story-item p {
    font-size: 16px;
  }
  .page-lead h2,
  h3.fz28 {
    font-size: 26px;
  }
  .page-lead p,
  .list-check > li,
  .aw-list--checked {
    font-size: 16px;
  }
  .process-item h3 {
    font-size: 22px;
  }
  .blog-title {
    font-size: 22px;
  }
  .section-letsgetstarted h2 {
    margin-top: 30px;
  }
  .landing-section h1 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-left {
    left: -30px;
  }
  .hero-right {
    right: -30px;
  }
  .pricing-head {
    min-height: 151px;
  }
  .table-recycleimg {
    width: auto;
  }
  .table-recycleimg img {
    display: block;
    max-width: 100%;
  }
  .page-recyclestep h3 {
    font-size: 18px;
  }
  .col-20 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .materials-tabnav .nav-tabs .nav-help {
    margin-left: 50px;
  }
  #help img {
    width: 90px;
  }
}

.howto-item {
  margin-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

.howto-item img {
  height: auto;
  width: 100%;
}

.tagline {
  text-align: center;
  max-width: 670px;
  margin: 0 auto 70px;
}
.tagline h2 {
  font-size: 28px;
}
.tagline p {
  font-size: 20px;
  text-align: justify;
  line-height: 30px;
}

.shop-wrap {
  margin: 0 -20px 20px;
}
.shop-item {
  margin-left: 20px;
  margin-right: 20px;
}

.aw-footer {
  text-align: left;
  padding-bottom: 0;
}
/* .aw-footer .container{max-width:800px;} */
.aw-footer h4 {
  font-size: 14px;
}
.aw-footer a {
  font-size: 15px;
}
.aw-footer li {
  font-size: 15px;
}
.footer-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
}
.footer-bottom .form-inline {
  max-width: 600px;
  margin: 0 auto;
}
.footer-bottom .form-inline .input-group {
  width: 290px;
}
.footer-bottom label {
  margin-right: 20px;
}
.footer-bottom .form-control {
  border-width: 1px;
  height: 34px;
  font-size: 14px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.footer-bottom .btn {
  font-size: 14px;
  line-height: 20px;
  border: 0;
  padding: 7px 15px;
  border-radius: 0 4px 4px 0;
  width: auto;
  background-color: #97adff;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  color: #fff;
}
.footer-bottom label {
  font-size: 14px;
}
.footer-logo {
  width: 60px;
}

/* Blog */
.section-blog .page-lead {
  max-width: 560px;
  margin-bottom: 0;
}
.section-blog-content {
  padding-top: 45px;
  padding-bottom: 30px;
}
.blog-item {
  border-bottom: 2px solid #ebebeb;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.blog-img > img {
  display: block;
  max-width: 100%;
}
.blog-title {
  font-size: 25px;
  font-weight: 700;
  font-family: 'Sofia Pro', sans-serif;
  line-height: 1.4;
  margin-bottom: 15px;
}

.blog-collapse {
  display: none;
}
.blog-pagination {
  padding-top: 20px;
}
.pagination .page-link {
  font-weight: 700;
}
.blog-list {
  font-family: 'Aleo', sans-serif;
  font-size: 18px;
}

/* Partners */
.section-partners .page-lead {
  max-width: 520px;
}
.aw-howto .page-lead {
  max-width: 440px;
}
.partners-left {
  padding: 50px 60px;
}
.partners-left h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
}
.partners-right {
  background-color: #e9e9e9;
  height: 100%;
}
.partners-right img {
  width: 100%;
  height: 100%;
}
.list-check {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}
.list-check > li {
  padding-left: 25px;
  position: relative;
  font-family: 'Aleo', sans-serif;
  margin-bottom: 10px;
  font-size: 17px;
}
.list-check > li::before {
  position: absolute;
  content: '\e906';
  font-family: 'icomoon' !important;
  color: #303a88;
  font-size: 16px;
  left: 0;
  top: 0;
}
.partners-content .col-md-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 53.77358%;
  flex: 0 0 53.77358%;
  max-width: 53.77358%;
}
.partners-content .col-md-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 46.22641%;
  flex: 0 0 46.22641%;
  max-width: 46.22641%;
}
.partners-valid,
.partners-invalid {
  display: none;
}
.partners-valid {
  max-width: 330px;
}
.partners-invalid {
  max-width: 300px;
}
.partners-valid p,
.partners-invalid p {
  margin-bottom: 25px;
  font-size: 17px;
}
#submit-address:after {
  content: "Let's go";
}

/* Pricing */
.section-pricing .page-lead {
  max-width: 580px;
}
.card-pricing img {
  height: 90px;
}
.card-pricing h3 {
  margin-bottom: 0;
  font-size: 20px;
}
.plan-desc {
  color: #979797;
  font-family: 'Aleo', sans-serif;
  font-size: 13px;
}
.pricing-head {
  padding-left: 15px;
  padding-right: 15px;
}
.pricing-features {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}
.pricing-features > li {
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
}
.pricing-features > li:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}
.pricing-features > li .pricing-features-details {
  font-family: 'Aleo', sans-serif;
  font-size: 13px;
  font-weight: 500;
}
.pricing-features > li .pricing-features-details svg {
  display: none;
}

/* Materials */
.section-materials .page-lead {
  max-width: 640px;
}
.page-recyclestep h3 {
  font-size: 20px;
  min-height: 44px;
}
.page-recyclestep .media.mt1 {
  margin-top: 60px;
}
.page-recyclestep .media.mt2 {
  margin-top: 40px;
}
.table-recycleimg {
  width: 291px;
}
.table.table-recycleimg th,
.table.table-recycleimg td {
  border-top: 0;
  text-align: center;
  padding: 0;
}
.table.table-recycleimg td.td-text {
  width: 33.3333%;
  vertical-align: middle;
}
.col-20 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.material-img {
  text-align: center;
  margin-bottom: 20px;
}
.material-img h4 {
  margin-bottom: 0;
  opacity: 0;
}
.material-img:hover h4 {
  opacity: 1;
}
.materials-tabnav {
  margin-bottom: 10px;
  position: relative;
}
.materials-tabnav .nav-tabs {
  border: 0;
}
.materials-tabnav .nav-tabs .nav-item {
  margin-bottom: 0;
}
.materials-tabnav .nav-tabs .nav-link {
  color: #000;
  border: 0 !important;
  font-size: 18px;
  position: relative;
}
.materials-tabnav .nav-tabs .nav-help {
  margin-left: 140px;
}
.materials-tabnav .nav-tabs .nav-help .nav-link {
  font-size: 28px;
  padding: 0;
}
.materials-tabnav .nav-tabs .nav-help .nav-link i {
  display: inline-block;
  vertical-align: middle;
  margin-top: -6px;
}
.materials-tabnav .nav-tabs .nav-link.active,
.materials-tabnav .nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
}
.materials-tabnav .nav-tabs .nav-link.active::before,
.materials-tabnav .nav-tabs .nav-item.show .nav-link::before {
  position: absolute;
  content: '';
  height: 2px;
  background-color: #000;
  bottom: 4px;
  left: 10px;
  right: 10px;
}
.materials-tabnav .nav-tabs .nav-help .nav-link::before {
  display: none;
}
.materials-tabnav .nav-tabs .nav-help .nav-link.active i:before {
  content: '\e909';
}
.right-tab {
  position: absolute;
  right: 0;
  top: 0;
}
#help .media img {
  width: 80px;
}

/* About */
.full-img {
  height: 326px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 326px;
}

/* Shop */
.shop-section {
  padding-top: 30px;
}
.heading-line {
  border-bottom: 1px solid #d1d1d1;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
}
.heading-line::after {
  position: absolute;
  content: '';
  width: 100px;
  border-top: 2px solid #39393b;
  left: 0;
  bottom: -1px;
}
.shop-wrap {
  margin: 0 -30px 20px;
}
.shop-item {
  display: block;
  text-decoration: none !important;
  position: relative;
  margin: 0 30px 30px;
  color: #39393b !important;
}
.shop-item img {
  display: block;
  width: 100%;
  max-width: 100%;
}
.shop-item h3 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 2;
  font-size: 26px;
  padding: 10px 20px;
}

.shop-search {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 40px;
}
.shop-search .form-control {
  border: 0;
}
.input-group-append,
.input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
}
.input-group-text i {
  margin-top: -4px;
}

@media (max-width: 1199px) {
  .howto-item {
    padding-left: 0;
    padding-right: 0;
  }
  .aw-grid--item.lg {
    padding-left: 50px;
    padding-right: 50px;
  }
  .current-process {
    width: 365px;
    height: 660px;
  }
  .cp-step2 {
    top: 185px;
  }
  .cp-step3 {
    top: 320px;
  }
  .cp-step4 {
    top: 400px;
  }
  .cp-step5 {
    top: 525px;
  }
  .our-process {
    width: 365px;
    height: 613px;
  }
  .op-step2 {
    right: -20px;
    top: 180px;
  }
  .op-step3 {
    top: 320px;
  }
  .op-step4 {
    right: -20px;
  }
  .whyitmatters-item .card-body {
    padding-left: 40px;
    padding-right: 40px;
  }

  .get-notified-form {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .story-content {
    margin-left: 200px;
  }
  .our-process,
  .current-process {
    width: auto;
    height: auto;
    background: none;
    padding-top: 0;
  }
  .op-step1,
  .op-step2,
  .op-step3,
  .op-step4,
  .cp-step1,
  .cp-step2,
  .cp-step3,
  .cp-step4,
  .cp-step5,
  .cp-step6 {
    position: relative;
    width: auto;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }
  .step {
    text-align: center;
  }
  .whyitmatters-item .card-body {
    padding-left: 40px;
    padding-right: 40px;
  }
  .whyitmatters-item h3 {
    font-size: 20px;
  }
  .section-path1,
  .section-letsgetstarted {
    padding-top: 50px;
  }
  .img-phone {
    margin: 0 auto;
  }
  .get-notified-form {
    margin-right: 0;
    max-width: 340px;
  }
  .story-item h3 {
    font-size: 24px;
  }
  .story-item p {
    font-size: 16px;
  }
  .page-lead h2,
  h3.fz28 {
    font-size: 26px;
  }
  .page-lead p,
  .list-check > li,
  .aw-list--checked {
    font-size: 16px;
  }
  .process-item h3 {
    font-size: 22px;
  }
  .blog-title {
    font-size: 22px;
  }
  .section-letsgetstarted h2 {
    margin-top: 30px;
  }
  .landing-section h1 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-left {
    left: -30px;
  }
  .hero-right {
    right: -30px;
  }
  .pricing-head {
    min-height: 151px;
  }
  .table-recycleimg {
    width: auto;
  }
  .table-recycleimg img {
    display: block;
    max-width: 100%;
  }
  .page-recyclestep h3 {
    font-size: 18px;
  }
  .col-20 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .materials-tabnav .nav-tabs .nav-help {
    margin-left: 50px;
  }
  #help img {
    width: 90px;
  }

  .shop-wrap {
    margin: 0 -20px 20px;
  }
  .shop-item {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .page-inner {
    padding-top: 60px;
  }
  /*.logo-text{height:30px;}*/
  .aw-header {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .aw-header .container {
    padding-top: 10px;
  }
  .aw-footer .container {
    max-width: 540px;
  }
  .aw-footer h4 {
    font-size: 13px;
    white-space: nowrap;
  }
  .footer-bottom {
    margin-top: 15px;
  }
  .footer-bottom .form-inline {
    max-width: 445px;
  }
  .story-content {
    margin-left: 100px;
  }
  .section-inner {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .process-item {
    padding: 30px;
  }
  .process-item h3 {
    font-size: 20px;
    min-height: 44px;
    margin-bottom: 30px;
  }
  .process-item p {
    font-size: 14px;
    margin-top: 15px;
  }
  .page-lead h2,
  h3.fz28 {
    font-size: 24px;
  }
  .page-lead p {
    font-size: 15px;
  }
  .col-6 + .col-6 .process-item::before {
    top: 30px;
    bottom: 40px;
  }
  .whyitmatters-item .card-body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .whyitmatters-item h3 {
    font-size: 18px;
  }
  .faq-accordion .btn {
    font-size: 16px;
  }
  .section-path1,
  .section-letsgetstarted {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .img-phone {
    margin: 0 auto;
  }
  .partners-content .col-md-7,
  .partners-content .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .partners-left h3 {
    font-size: 22px;
  }

  .get-notified-form {
    margin-right: 0;
    max-width: 300px;
  }
  body.page-inner .aw-header,
  body.page-inner .aw-header.navbar-white,
  .nav-open .aw-header.navbar-white {
    background-color: #ffffff;
  }
  body.page-inner .aw-header .cls-1,
  .nav-open .aw-header .cls-1 {
    fill: #fff;
  }
  nav.aw-nav--mobile li a {
    font-size: 16px;
  }
  .btn {
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
  }
  .tagline {
    margin-bottom: 45px;
  }
  .tagline h2 {
    font-size: 24px;
  }
  .tagline p {
    font-size: 16px;
  }
  .page-lead h2 {
    font-size: 24px;
  }
  .process-item h3 {
    font-size: 20px;
  }
  .page-section.aw-ourstory--icons {
    padding-top: 0;
  }
  .faq-accordion .card-body,
  .whyitmatters-item p,
  .list-check > li,
  .aw-list--checked {
    font-size: 14px;
  }
  .blog-title {
    font-size: 20px;
  }
  .section-letsgetstarted h2 {
    margin-top: 0;
  }
  nav.aw-nav--mobile li a {
    padding: 13px 10px;
  }
  nav.aw-nav--middle {
    margin: 0 0 10px;
  }
  .landing-section h1 {
    max-width: 300px;
    font-size: 32px;
  }
  .landing-section h2 {
    font-size: 24px;
    font-weight: 300;
  }
  .landing-section p {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  .pricing-head {
    min-height: auto;
  }
  .card-pricing {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: transparent;
  }
  .card-pricing .card-body {
    padding: 0;
  }
  .pricing-box {
    box-shadow: 0 1px 7px 0 rgba(226, 226, 226, 0.5);
    -webkit-box-shadow: 0 1px 7px 0 rgba(226, 226, 226, 0.5);
    background-color: #fff;
    margin-bottom: 30px;
  }

  .right-tab {
    position: relative;
    right: auto;
    top: auto;
  }
  .col-20 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .material-img img {
    max-width: 100%;
    display: inline-block;
  }
  .page-recyclestep .media.mt1,
  .page-recyclestep .media.mt2 {
    margin-top: 0;
  }
  .page-recyclestep .media {
    margin-bottom: 30px;
  }
  .page-recyclestep h3 {
    min-height: auto;
  }
  #help img {
    width: 60px;
  }
  h3 {
    font-size: 18px;
    margin: 0;
  }
  .page-recyclestep h3 {
    font-size: 16px;
  }

  .wxs {
    max-width: 220px;
    margin: 0 auto;
    text-align: left;
    position: relative;
  }
  .pricing-features > li .pricing-features-details {
    font-family: 'Aleo', sans-serif;
    font-size: 15px;
    font-weight: 700;
  }
  .wxs .pricing-features-desktop {
    display: none;
  }
  .pricing-features > li .pricing-features-details svg {
    display: inline;
  }
  /*.wxs svg{position: absolute;right:0;top:6px;}*/
  .col-md-4:nth-child(2) .pricing-features > li:last-child {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .full-img {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .header-wrap + .aw-main {
    padding-top: 70px;
  }
  p {
    font-size: 16px;
  }
  .page-title {
    font-size: 22px;
  }
  .page-title.fz28,
  h3.fz28 {
    font-size: 20px;
  }
  .aw-logo {
    height: 20px;
    margin-left: -4px;
  }
  .aw-logo svg {
    width: auto;
    height: 40px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-section {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .bg-card {
    margin: 0 -30px 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .aw-our--story {
    padding-bottom: 0;
  }
  .aw-grid--item.lg {
    padding: 35px 30px;
    min-height: auto;
  }
  .section-inner {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .section-how-it-works,
  .section-why-it-matters {
    padding-bottom: 0;
  }
  .section-how-it-works .card {
    margin-left: -30px;
    margin-right: -30px;
  }
  .page-lead {
    margin-bottom: 40px;
  }
  .page-lead h2 {
    font-size: 20px;
  }
  .page-lead p {
    font-size: 14px;
  }
  .process-item h3,
  .process-item h3 {
    font-size: 14px;
  }
  .whyitmatters-item {
    margin: 0 -30px 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding-top: 20px;
  }
  .whyitmatters-item .card-body {
    padding: 10px 40px 0;
  }
  .col-sm-6:last-child .whyitmatters-item .card-body {
    padding-bottom: 25px;
  }
  .whyitmatters-item h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .section-faq .page-lead {
    text-align: center;
    margin-bottom: 20px;
  }
  .section-blog .page-lead,
  .section-partners .page-lead {
    text-align: center;
  }
  .section-blog-content {
    padding-top: 0;
  }
  .blog-item {
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .blog-img {
    margin-bottom: 25px;
  }
  .blog-desc {
    padding-left: 30px;
    padding-right: 30px;
  }
  .blog-title {
    font-size: 18px;
  }
  .blog-date,
  .readmore {
    font-size: 14px;
  }
  .blog-list {
    font-size: 13px;
  }
  .list-check > li::before {
    font-size: 14px;
    top: -2px;
  }
  .partners-left h3 {
    font-size: 20px;
  }
  .partners-left {
    padding: 25px;
  }

  .get-notified-form {
    margin-right: 70px;
    max-width: 260px;
  }
  .get-notified-form p {
    margin-right: 70px;
    max-width: 280px;
  }
  .get-notified-form h2 {
    max-width: 150px;
  }
  .section-path1,
  .section-letsgetstarted {
    padding-bottom: 0;
  }
  .email-success-wrap {
    max-width: 240px;
    margin-left: 0;
  }
  .story-item h3 {
    font-size: 22px;
  }
  .story-item p {
    font-size: 15px;
  }
  .process-item p,
  .whyitmatters-item p,
  .faq-accordion .card-body {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }
  .page-title {
    font-size: 22px;
  }
  .page-title.fz28,
  h3.fz28 {
    font-size: 20px;
  }
  .aw-logo {
    height: 20px;
    margin-left: -4px;
  }
  .aw-logo svg {
    width: auto;
    height: 40px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-section {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .bg-card {
    margin: 0 -30px 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .aw-our--story {
    padding-bottom: 0;
  }
  .aw-grid--item.lg {
    padding: 35px 30px;
    min-height: auto;
  }
  .section-inner {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .section-how-it-works .card {
    margin-left: -30px;
    margin-right: -30px;
  }
  .page-lead {
    margin-bottom: 40px;
  }
  .page-lead h2 {
    font-size: 20px;
  }
  .page-lead p {
    font-size: 14px;
  }
  .process-item h3,
  .process-item h3 {
    font-size: 14px;
  }
  .whyitmatters-item {
    margin: 0 -30px 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding-top: 20px;
  }
  .whyitmatters-item .card-body {
    padding: 10px 40px 0;
  }
  .col-sm-6:last-child .whyitmatters-item .card-body {
    padding-bottom: 25px;
  }
  .whyitmatters-item h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .section-faq .page-lead {
    text-align: center;
    margin-bottom: 20px;
  }
  .section-blog .page-lead,
  .section-partners .page-lead {
    text-align: center;
  }
  .section-blog-content {
    padding-top: 0;
  }
  .blog-item {
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .blog-img {
    margin-bottom: 25px;
  }
  .blog-desc {
    padding-left: 30px;
    padding-right: 30px;
  }
  .blog-title {
    font-size: 18px;
  }
  .blog-date,
  .readmore {
    font-size: 14px;
  }
  .blog-list {
    font-size: 13px;
  }
  .list-check > li::before {
    font-size: 14px;
    top: -2px;
  }
  .partners-left h3 {
    font-size: 20px;
  }
  .partners-left {
    padding: 25px;
  }
  .section-path1,
  .section-letsgetstarted {
    padding-bottom: 0;
  }
  .email-success-wrap {
    max-width: 240px;
    margin-left: 0;
  }
  .story-item h3 {
    font-size: 22px;
  }
  .story-item p {
    font-size: 15px;
  }
  .process-item p,
  .whyitmatters-item p,
  .faq-accordion .card-body {
    font-size: 12px;
  }
}

.content {
  min-height: 400px;
}

/* Modal */
.modal-content {
  border: none;
  border-radius: 0;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

@media (min-width: 992px) {
  .modal-dialog {
    min-width: 600px;
  }
}
.modal-backdrop.fade.show {
  opacity: 0.8;
  background-color: white;
}

.modal-footer {
  border-top: none;
  justify-content: flex-start;
}

.btn-icon {
  border: none;
}

.modal-header {
  border-bottom: none;
  justify-content: flex-end;
}

.modal-header h2 {
  width: 100%;
  text-align: center;
}

.modal-missing .modal-header {
  justify-content: flex-start;
}

.btn-icon--back {
  background: url(../images/left_arrow2.png) no-repeat;
  background-size: 12px 20px;
  width: 12px;
  height: 20px;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.btn-icon--close {
  background: url(../images/cancel_cross2.png) center no-repeat;
  background-size: 20px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 900;
}

.space-bw {
  display: flex;
  justify-content: space-between;
}

.btn-main {
  font-size: 22px;
  height: 60px;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  background-color: #cecece;
  display: block;
}
.btn-main.active {
  font-size: 22px;
  height: 60px;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  background-color: #97adff;
  display: block;
}
.btn-main.disabled {
  background-color: #353535;
}

.btn-main.white {
  background-color: #fff;
  border: 1px solid #ececec;
  color: #e0a5a7;
}

.btn-blue-fb {
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 4px;
  padding: 0.7em 1.5em;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  background-color: #36549d;
  display: block;
}

.btn-blue-fb.inactive {
  background-color: #cecece;
}

.btn-transparent {
  width: auto;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: bold;
  color: #000;
  padding: 0;
  margin: 0;
}
.btn-transparent.dropdown-toggle {
  font-size: 17px;
  font-weight: normal;
  text-transform: uppercase;
}

.btn-transparent:focus {
  box-shadow: none;
}
.btn-transparent-light {
  color: #6060a8;
}

.account-dropdown .dropdown-menu {
  width: 230px;
  padding: 10px 15px;
  border: none;
  border-radius: 0;
  left: 50%;
  margin-left: -115px;
  margin-top: 18px;
  border: 0;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

.account-dropdown .dropdown-item {
  padding: 15px 0;
}
.account-dropdown .dropdown-item + .dropdown-item {
  border-top: 1px solid #ececec;
}
.account-dropdown .dropdown-item + .dropdown-item:nth-child(2) {
  border-top: none;
}

.aw-input--group {
  position: relative;
}
.aw-input--group.aw-input--group-sm button {
  top: 27%;
  right: 13px;
}

.aw-input--group button {
  position: absolute;
  right: 0;
  top: 50%;
  width: inherit;
}

.aw-input--control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #97adff;
  padding: 0 10px;
}
.aw-input--control.black {
  border-color: #000;
}
.aw-input--control[readonly]:not(.aw-input--location) {
  background: none;
}

.input-merged {
  display: flex;
}

.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}
.card-element-input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #97adff;
  padding: 20px 0;
}

.card-element-input.StripeElement--focus {
  border-bottom: 1px solid #c0d6cd;
}
textarea.aw-input--control,
textarea.aw-input--control:focus {
  width: 100%;
  border: none;
  box-shadow: none;
  outline: none;
}
.aw-input--control-large {
  height: 80px;
  font-size: 20px;
}
.ElementsApp input {
  background: red !important;
}

.aw-input--bordered,
textarea.aw-input--bordered,
.aw-input--bordered:focus,
textarea.aw-input--bordered:focus {
  border: 1px solid #cecece;
}
.aw-input--bordered-strong,
textarea.aw-input--bordered-strong,
.aw-input--bordered-strong:focus,
textarea.aw-input--bordered-strong:focus {
  border: 1px solid #000;
}

.aw-input--control:focus {
  box-shadow: none;
  border: none;
  border-bottom: 2px solid #97adff;
}
.aw-input--bordered:focus,
.aw-input--bordered.focus-input {
  border: 2px solid #97adff;
}
.aw-input--center {
  text-align: center;
}

.modal-body-bordertop {
  border-top: 1px solid #ececec;
}

.addresses--icon::before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 22px;
}

.button {
  cursor: pointer;
}
.addresses--default {
  position: absolute;
  right: 0;
  top: 30%;
  width: inherit;
  color: #263a52;
  font-size: 20px;
  font-weight: bold;
}

.btn-block {
  display: block;
  width: auto;
}

i.ico::before {
  content: '';
}

.ico-arrow-right {
  width: 12px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/right_arrow2.png) no-repeat;
}
.ico-add-circle {
  width: 33px;
  height: 33px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/plus_eclipse3.png) no-repeat;
}

.ico-add-square {
  width: 30px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/plus_rectangle2.png) no-repeat;
}
.btn-icon-transparent {
  padding: 0;
  margin: 0;
  background: none;
  width: auto;
}
.btn-icon-transparent:focus {
  box-shadow: none;
}

.addresses--address,
.payments--card {
  font-size: 20px;
  font-weight: bold;
}
.addresses--info {
  color: #bbb;
  font-size: 15px;
  display: block;
}
ul.list-addresses,
ul.list-payments {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-payments.list-payments--noborder li {
  border: none;
}

ul.list-addresses li,
ul.list-payments li {
  position: relative;
  padding: 2rem 1rem;
  border-bottom: 1px solid #cecece;
}

ul.list-payments li {
  background: url('../images/card.png') no-repeat;
  background-size: 27px 18px;
  background-position: left center;
}

.login-wrap,
.signup-wrap {
  width: 80%;
  margin: 0 auto;
  font-family: 'Sofia Pro';
}

.product-modal-wrap {
  padding: 0 30px;
}

.signup-wrap {
  padding: 0 10px;
  padding-bottom: 34px;
}

.signup-wrap .login-wrap,
.login-wrap .login-wrap {
  margin-top: 4rem;
  width: 100%;
}

.order-wrap {
  padding: 0 70px;
}

.login-wrap h3,
.signup-wrap h3 {
  font-weight: 900;
  font-size: 28px;
}
.login-wrap span,
.signup-wrap span {
  display: block;
  font-size: 16px;
}

.signup-wrap .btn,
.login-wrap .btn {
  width: 60%;
  border-radius: 30px;
  height: 50px;
  padding-top: 15px;
}

.login-wrap a.forgot-text {
  font-size: 18px;
  display: block;
  text-align: center;
}

span.tnc {
  font-size: 14px;
  color: #8a8a8a;
}

span.tnc i {
  color: #000;
}

.text-error {
  color: #ff0000;
  font-size: 20px;
}

.util-font-size-14 {
  font-size: 14px;
}

.text-success {
  color: green;
  font-size: 20px;
}

.info-popup {
  font-size: 15px;
}

span.text-disclaimer {
  font-size: 10px;
}

.text-block {
  display: block;
}
.fancy-spacing {
  display: flex;
  justify-content: space-around;
  color: #cecece;
}
.fancy-spacing hr {
  display: block;
  /* margin-top: 0.5em; */
  /* margin-bottom: 0.5em; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* border-style: inset; */
  /* border-width: 1px; */
  border: 1px solid #cecece;
  width: 33%;
}

.t-18 {
  font-size: 18px !important;
}

.btn-text--login {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  text-decoration: underline;
}

/* about */
.page-header {
  padding: 25px 0px 5px;
}

.line {
  border: 1px solid #ececec;
}

/* search form */
.search-form {
  width: 80%;
  /* height: 70px; */
  border-radius: 10px;
  outline: none;
  padding: 10px;
  border: 2px solid #ececec;
  font-size: 30px;
  /* display: flex */
}

.search-form input[type='text'] {
  border: none;
  outline: none;
  width: 80%;
  margin-left: 20px;
}

/* .list{

} */

.list-header {
  border-bottom: 2px solid #ececec;
  /* padding: -5px; */
}

.list-icon {
  margin-right: 20px;
  margin-left: 20px;
  /* margin-top: 4px; */
}

.list-bordered {
  border-bottom: 1px solid #ececec !important;
  margin: 5px 0;
}

.help-btn {
  color: black;
  border-radius: 10px;
  border: 2px solid #97adff;
  width: 100px;
  height: 40px;
  font-weight: bold;
  background-color: #fff;
}

a.view-all {
  color: #bbbbbb !important;
}

.list-group-item {
  padding: 10px;
  border: none;
}

.borderless td,
.borderless th {
  border: none !important;
}

.btn-help {
  padding: 5px;
  width: 100%;
}

.list-link {
  color: #6060a8 !important;
  font-size: 17px;
  /* font-size: 9px !important; */
}

.list-link h4 {
  font-size: 17px;
}

.btn-left {
  float: left;
  float: left;
}

.referral-wrap {
  border-radius: 6px;
  border: 2px dashed #ececec;
  display: flex;
  justify-content: space-between;
  background-color: #fae1ff;
}

.referral-wrap .referral-link {
  font-size: 18px;
}

.modal-outline.modal-dialog {
  box-shadow: none;
}
.modal-outline .modal-content {
  border: 1px solid #ececec;
}

.modal-outline .modal-header {
  border-bottom: 1px solid #ececec;
  padding: 30px 50px 0 50px;
}

.modal-outline .modal-body {
  padding: 10px 50px;
}

.form-address-info {
  display: flex;
  justify-content: space-between;
  padding: 0 11px;
  border-bottom: 1px solid #ececec;
}

.form-address-info span {
  font-size: 20px;
  font-weight: bold;
}

.aw-input--location {
  background: url('../images/location_icon.png') no-repeat;
  background-color: #fff !important;
  padding-left: 12%;
  background-position: 5%;
}

.text-black {
  color: black;
}
.text-blue {
  color: #6060a8 !important;
  font-size: 20px;
  font-weight: bold;
}
.text-bold {
  font-weight: bold;
}
.text-normal {
  font-weight: normal;
}

.text-caps {
  text-transform: uppercase;
}

.text-order {
  font-size: 18px !important;
}

.form-group.input-merged input {
  display: inline-block;
}

.card {
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.3);
  padding: 90px 110px;
  max-width: 600px;
  margin: 0 auto;
}

.card.card-bordered {
  border: 1px solid #ececec;
  box-shadow: 2px 20px 17px rgba(0, 0, 0, 0.3);
}

.order-summary .card {
  padding: 50px 25px;
  width: auto;
}

.order-summary .item {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ececec;
}

.order-summary .item-name {
  font-size: 20px;
}

.order-summary .item-qty {
  font-size: 18px;
}

.order-summary .item-right {
  text-align: right;
}

.order-summary .item-detail,
.order-summary .item-link a,
.order-summary .item-price {
  font-size: 13px !important;
}
.item-detail {
  color: #bbb;
}

.aw--orders th {
  font-size: 25px;
}

.aw--orders td,
.aw--orders .order-item-content {
  font-size: 18px;
}
.order-item,
.order-item-content-wrapper {
  position: relative;
}
.order-item-content-wrapper .order-item-content {
  margin-right: 200px;
}
.text-report {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

/* product */

.product-top {
  border-bottom: 1px solid #ececec;
  padding: 15px 0;
  width: 100%;
  background: #fff;
}

.product-top h3 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
  padding-top: 10px;
}
.product-top h3 i {
  font-size: 14px;
  float: right;
  margin-top: 2px;
}
.left-column {
  display: inline-block;
  position: relative;
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}
.left-column.hide {
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
  display: none;
}
.left-column.hide.hide-block {
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
  display: inline-block;
}
.left-column {
  transform: translateX(0%);
  transition: all 0.5s ease;
}
.left-column span {
  padding-top: 7px;
}
.left-column::after {
  position: absolute;
  content: '';
  border-right: 1px solid #ddd;
  top: 0;
  bottom: 0;
  right: 0;
}
.product-content {
  min-height: 600px;
  padding-bottom: 50px;
}

.dropdown h3 {
  cursor: pointer;
}
.dropdown-fwidth .dropdown-menu {
  right: 0;
  border-radius: 0;
  border: 0;
  border-top: 1px solid #ececec;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  max-height: 290px;
  overflow-y: auto;
}

.product-top .dropdown-menu {
  right: 0;
  border-radius: 0;
  border: 0;
  border-top: 1px solid #ececec;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  max-height: 290px;
  overflow-y: auto;
}
.dropdown-fwidth .dropdown-item,
.account-dropdown .dropdown-item {
  padding: 10px 0;
  position: relative;
}
.dropdown-fwidth .dropdown-item:hover,
.dropdown-fwidth .dropdown-item:focus,
.dropdown-fwidth .dropdown-item.active,
.dropdown-fwidth .dropdown-item:active,
.account-dropdown .dropdown-item:hover,
.account-dropdown .dropdown-item:focus {
  background-color: transparent;
  color: #212529;
}
.dropdown-fwidth .dropdown-item::before,
.account-dropdown .dropdown-item::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: -15px;
  border-left: 2px solid transparent;
}
.dropdown-fwidth .dropdown-item:hover::before,
.dropdown-fwidth .dropdown-item:focus::before,
.account-dropdown .dropdown-item:hover::before,
.account-dropdown .dropdown-item:focus:before {
  border-left-color: #6060a8;
}
.dropdown-fwidth .dropdown-item + .dropdown-item {
  border-top: 1px solid #ececec;
}
.product-top .dropdown-menu {
  margin-top: 15px;
}
.rbt-menu.dropdown-menu {
  margin-top: 0;
  border: 0;
  left: -38px !important;
  width: inherit !important;
}

a.dropdown-item.lg,
span.dropdown-item.lg {
  font-size: 20px;
}

.search-product .form-control {
  border: 1px solid #979797;
  border-left: 0;
  padding: 6px 10px;
  border-radius: 0;
  height: auto;
  line-height: 26px;
  font-size: 16px;
}
.search-product .form-control:focus {
  box-shadow: none;
}
.search-product .input-group-text {
  padding: 3px 5px 3px 15px;
  background-color: #fff;
  border-color: #979797;
  border-radius: 0;
  border-right: none;
}
.product-top .dropdown-cart-wrapper {
  margin-left: 20px;
}
.product-top .product-top-big-logo {
  margin-top: 5px;
}
.search-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.product-cart-counter {
  cursor: pointer;
}

.dropdown-wrapper {
  position: absolute;
  top: 100%;
  z-index: 1000;
  left: 0;
}

.dropdown-wrapper > .dropdown-menu {
  position: relative;
  top: 0;
}

.product-cart-counter + .dropdown-wrapper {
  right: 0;
  left: auto;
}

.dropdown-address,
.dropdown-time,
.dropdown-categories {
  height: 100%;
}

.bdr-right {
  border-right: 1px solid #ddd;
}

.product-cart-counter:hover + .dropdown-wrapper > .dropdown-menu,
.dropdown-address:hover + .dropdown-wrapper > .dropdown-menu,
.dropdown-time:hover + .dropdown-wrapper > .dropdown-menu,
.dropdown-categories:hover + .dropdown-wrapper > .dropdown-menu,
.dropdown-wrapper:hover > .dropdown-menu {
  display: block;
}

.dropdown-details,
.dropdown-details-mobile {
  margin-left: 10px;
}

.dropdown-details {
  display: none;
}

.dropdown-address:hover .dropdown-details,
.dropdown-time:hover .dropdown-details {
  display: block;
}

.dropdown-cart .dropdown-menu {
  top: 2px;
  left: auto;
  right: 0;
  width: 340px;
  max-height: none;
  padding-bottom: 20px;
  overflow: visible;
}
.dropdown-cart .dropdown-menu::before,
.dropdown-cart .dropdown-menu::after {
  position: absolute;
  content: '';
  top: -16px;
  right: 45px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #ececec;
}
.dropdown-cart .dropdown-menu::after {
  border-bottom: 15px solid #fff;
  top: -14px;
}
.dropdown-cart h3 {
  font-size: 24px;
  font-weight: 700;
}
.order-scroll {
  max-height: calc(50vh - 100px);
  overflow-y: auto;
  padding-right: 10px;
}

.delivery-fee-info {
  font-style: italic;
  color: purple;
  font-size: 10px;
  display: block;
  padding-top: 3px;
}
/* .right-column{padding-left:50px;} */

.product-content-left {
  font-size: 15px;
  position: sticky;
  top: 162px;
  border-right: 1px solid #cecece;
}
.header-wrap.top-bar-open + .aw-main .product-content-left {
  top: 181px;
}
.product-content-left.fixed {
  position: fixed;
  top: 51px;
}
.product-content-left h4 {
  font-weight: bold;
  font-size: 15px;
}
.text-violet {
  color: #6060a8 !important;
}
.product-content-left div > ul {
  list-style: none;
}
.product-content-left div > ul li {
  margin-left: -40px;
  font-weight: 500;
}
.product-content-left a {
  color: #39393b;
}
.product-content-left-scroll {
  max-height: calc(100vh - 181px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1rem;
}

.product-breadcrumb {
  padding: 0px 0 10px;
}

.product {
  position: relative;
}

.product .container-fluid {
  padding: 0;
  overflow: hidden;
}

.product h2 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0px;
}

.product-sub {
  position: relative;
  border-bottom: 1px solid #ececec;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.product-sub a {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #39393b;
}
.product-sub h5 {
  margin-bottom: 0;
}

.product-thumbnail {
  margin-bottom: 40px;
  cursor: pointer;
}

.product-thumbnail img {
  display: block;
  width: 100%;
  height: auto;
}

.product-detail {
  margin-top: 20px;
}

.product-price {
  color: #6060a8;
}
.product-packaged {
  font-style: italic;
}
.product-packaged.out-of-stock {
  color: red;
}
.product-packaged.unavailable {
  color: #6060a8;
}

.product-weight {
  text-align: right;
}

.product-modal-left {
  border-right: 1px solid #ececec;
}

.product-producer {
  font-size: 13px;
}

@media (max-width: 768px) {
  .product-producer {
    font-size: 9px;
  }
}

@media (min-width: 768px) {
  .product-desc {
    font-size: 16px;
  }
}

/* New Style */
.header-wrap {
  /*position: relative;*/
  z-index: 1030;
}

.card1 {
  border: 1px solid #ececec;
  margin-bottom: 30px;
}
.card1.error {
  border: 2px solid red;
}
.card1.card-shadow {
  box-shadow: 2px 20px 17px rgba(0, 0, 0, 0.3);
}
.address-phone {
  color: #bbb;
  font-size: 13px;
}
.address-rbtn {
  position: absolute;
  right: 0;
  top: 20px;
}
.address-rbtn.sm {
  font-weight: normal;
  font-size: 18px;
}
.link-blue,
.link-blue:hover {
  color: #6060a8;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
}
/* .addAdressForm, .addPaymentForm{padding-left: 30px;} */
.custom-control {
  padding-left: 30px;
}
.custom-radio.bb1 {
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.custom-radio.bb1::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid #ececec;
  left: 30px;
  right: 0;
  bottom: 0;
}
.card-body.lock .custom-radio.bb1::after {
  border-bottom: none;
}
.custom-control-label {
  font-size: 14px;
}
.custom-control-label::before {
  background-color: transparent;
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  top: 2px;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.card-body.lock .custom-radio.bb1 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before,
.custom-control.active .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after,
.custom-control.active .custom-control-label::after {
  background-image: none;
  background-color: #000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 6px;
  left: 4px;
}
.custom-radio.red .custom-control-input:checked ~ .custom-control-label::after,
.custom-control.red.active .custom-control-label::after {
  background-color: #dc3545;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  left: 1px;
  top: 3px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.input1 {
  border: 0;
  border-bottom: 1px solid #cecece;
  border-radius: 0;
  height: 40px;
  padding-left: 0;
  padding-right: 10px;
}
.input1:focus {
  box-shadow: none;
  border-bottom-color: #c0d6cd;
}
.input2 {
  border: 1px solid #cecece;
  border-radius: 0;
}
.input2:focus {
  box-shadow: none;
  border-color: #c0d6cd;
}
textarea.input1,
textarea.input2 {
  height: auto;
}
.btn-main.inline-round {
  width: auto;
  display: inline-block;
  border-radius: 60px;
  padding-left: 30px;
  padding-right: 30px;
}
.error-msg {
  color: #ff0000;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-dropdown-outline {
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #ececec;
  width: 100%;
  display: block;
  text-align: left;
  font-weight: 400;
  height: auto;
  padding: 20px;
}
.btn-dropdown-outline.dropdown-toggle::after {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -2px;
}
.btn-dropdown-outline + .dropdown-menu {
  margin-top: 0;
  border-radius: 0;
  right: 0;
  max-height: 300px;
  overflow-y: scroll;
}
.text-muted {
  color: #888 !important;
}
.text-muted-alert {
  color: red !important;
}
.dropdown-header {
  font-size: 20px;
  color: #39393b;
}
.p-r {
  position: relative;
}
h3.p-r .address-rbtn {
  top: 9px;
}

.item-summaries {
  padding-top: 20px;
  padding-bottom: 20px;
}
.summary {
  color: #888;
  line-height: 30px;
  position: relative;
  font-weight: 500;
}
.summary span + span,
.item-total span + span {
  float: right;
}
.item-total {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 10px;
}
.dropdown-item .item-total {
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
}
hr {
  border-top-color: #ececec;
}

.summary .popover {
  left: 134px;
  top: -6px;
}

.summary .popover .arrow {
  top: 6px;
}

.summary .popover {
  display: none;
}
.summary.open .popover {
  display: block;
}

.modal-product-price span {
  font-size: 22px;
  color: #6060a8;
  font-weight: 700;
}

@media (max-width: 576px) {
  .confirm-submit {
    display: block;
    width: 100% !important;
    margin-bottom: 15px;
  }
}

.package-info {
  position: relative;
  max-width: 160px;
}
.package-info-popover {
  position: absolute;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px;
  width: 200px;
  left: 100%;
  top: 0;
  display: none;
  z-index: 100;
}
.package-info.open .package-info-popover {
  display: block;
}
.package-info-popover::before,
.package-info-popover::after {
  position: absolute;
  content: '';
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 40px solid #ddd;
  left: -40px;
  top: 0;
}
.package-info-popover::after {
  border-right: 40px solid #fff;
  left: -38px;
}
.package-info-popover h4 {
  font-size: 16px;
}
.package-info-popover p {
  font-size: 14px;
}
.package-info-popover p:last-child {
  margin-bottom: 0;
}

.substitue-popover {
  left: auto;
  right: 0;
  top: 20px;
}
.substitue-popover:before,
.substitue-popover:after,
.addon-popover:before,
.addon-popover:after {
  display: none;
}

.addon-popover {
  left: 50%;
  top: 18px;
}

.jar-icons > div {
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}

.a-plus-image img {
  max-width: 100%;
}

.comments-container {
  width: 90%;
  margin: 0 auto;
}

.comment {
  margin: 15px 0;
}

.product-rating-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.star {
  margin: 0 0.2em;
  font-size: 1.5em;
  cursor: default;
}

.product-rating-label {
  margin-right: 10px;
}

.star.clickable {
  cursor: pointer;
}

.stars-container {
  display: flex;
  margin: 5px 0;
}

.product-ratings-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-comment-input {
  min-height: 100px;
}

button.rate-btn {
  border-radius: 10px;
  margin-top: 10px;
}

.rating-message {
  display: none;
  margin-top: 16px;
  font-size: 16px;
}

.rating-message.text-error {
  display: block;
}

.rating-message.text-success {
  display: block;
  color: green;
}

.modal-header--sm {
  margin: 40px 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .modal-dialog.modal-lg {
    max-width: none;
    margin-left: 20px;
    margin-right: 20px;
  }
  .product-top.fixed .small-logo {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .dropdown-menu {
    font-size: 14px;
  }
  .dropdown-header {
    font-size: 16px;
    font-weight: 700;
  }
  .dropdown-header,
  .dropdown-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .right-column {
    padding-left: 15px;
  }
  .product-top .media-right {
    margin-left: 15px;
  }
  .product-content-left {
    padding-top: 50px;
  }
}
@media (max-width: 576px) {
  .left-column::after {
    border-right: 0;
    left: 15px;
    right: 15px;
    top: auto;
  }
  .left-column {
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .package-info-popover {
    margin-left: -30px;
    max-width: 140px;
  }
  .media-xs,
  .media-xs .media-body {
    display: block;
  }
  .media-xs .media-body {
    margin-top: 20px;
  }
  .media-xs .media-img.mr-4 {
    margin-right: 0 !important;
  }
  .media-xs .media-img > img {
    width: 100%;
    display: block;
    max-width: 100%;
  }
  .product-top .dropdown-menu {
    margin-top: 9px;
  }
  .dropdown-cart .dropdown-menu {
    max-width: 280px;
  }
}

.modal-content .checkout-wrap .btn-dropdown-outline {
  padding-right: 40px;
}

.checkout-wrap {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 720px) {
  .checkout-wrap {
    display: block;
    justify-content: unset;
  }
  .help-box {
    margin: 50px 0 0;
  }
}

.item-link a,
.pointer {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: none !important;
  background-color: #fff !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  border-radius: 0 0 2px 2px;
  padding: 4px;
  z-index: 100;
}

.autocomplete-dropdown-container .loading {
  padding: 8px;
  display: inline;
}
input[aria-expanded='true'] + .autocomplete-dropdown-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 8px;
  background-color: #fafafa;
}

.dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.form-check-label.check-sidebar input {
  margin-left: 0;
}
.form-check-label.check-sidebar span {
  margin-left: 20px;
}

.carousel {
  background: #ddeff7;
  position: relative;
}

.carousel .carousel-inner {
  height: auto;
  margin: auto;
}
.carousel .carousel-inner img {
  height: auto;
  width: 100%;
}

.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0);
  border: 2.5px solid #fff;
  margin-left: 10px;
  margin-right: 10px;
}
/* .carousel-indicators li::after { */
/*   position: absolute; */
/*   [> bottom: -10px; <] */
/*   left: 2.5px; */
/*   top: 2.5px; */
/*   display: inline-block; */
/*   width: 100%; */
/*   height: 10px; */
/*   content: ""; */
/*   width: 10px; */
/*   height: 10px; */
/*   background: #ddeff7; */
/*   border-radius: 10px; */
/* } */
.carousel-indicators .active {
  background: #fff;
  background-color: #fff;
}

/* .carousel-indicators li.active::after {
  background: #fff;
} */
a.nav-link.no-underline:hover {
  text-decoration: none;
}
li.no-underline a:hover::after {
  text-decoration: none;
  border: none !important;
}

button.btn.btn-danger.btn-add-cart {
  height: 50px;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 0.5;
  background-color: #97adff;
  border-color: #97adff;
}
button.btn.btn-danger.btn-add-cart.inactive {
  background-color: #cecece;
  border-color: #cecece;
}
.product-top.fixed + .product-content .product-content-left,
.product-top.fixed + .product-content .product-content-right {
  margin-top: 0;
}

.aw-input--location.aw-input--small {
  background-size: 15px;
  background-position: 0% 53%;
  padding-left: 7%;
}

.list-group-item .answer {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.list-group-item.active .answer {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.list-group-item.active {
  background: none;
  border: none;
  color: #000;
}
.list-group-item .badge {
  transition: transform 0.2s;
}
.list-group-item.active .badge {
  transition: transform 0.2s;
  transform: rotate(90deg);
}
a {
  cursor: pointer !important;
}
.aw-footer .container {
  max-width: 1000px;
}

.checkout-wrap .card1.delivery {
  width: 400px;
}

/* media mobile */
@media (max-width: 576px) {
  .product h2 {
    font-size: 23px;
  }
  .product-price,
  .product-desc,
  .product-weight,
  .product-packaged {
    font-size: 0.7rem;
  }

  .search-form {
    width: 100%;
  }
  .address-rbtn {
    bottom: 20px;
    top: unset;
  }
  .aw--orders th {
    font-size: 18px;
  }
  .text-report {
    display: block;
    position: relative;
  }
  .order-item-content-wrapper .order-item-content {
    margin-right: 0;
  }
  .order-wrap {
    padding: 0;
  }
  .modal-outline .modal-body {
    padding: 10px;
  }
  .login-wrap,
  .signup-wrap {
    width: 90%;
  }
  .modal-header--sm {
    margin: 12px;
  }

  .modal-header--sm-nomargin {
    margin: 0;
  }
  .addresses--default {
    position: absolute;
    top: unset;
    bottom: 28%;
  }

  .btn-login {
    border: 0;
    float: right;
    padding: 20px 10px;
  }

  .carousel-item {
    width: 100%;
    margin: auto;
    height: calc(50vh - 80px);
  }
  .carousel-item img {
    margin-top: -50%;
  }

  .product-thumbnail img {
    display: block;
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
  .product-content-left.fixed {
    position: relative;
    top: unset;
  }

  .big-arrow {
    right: -37px !important;
  }
  .big-arrow.right-arrow {
    left: -37px !important;
  }
  .checkout-wrap .card1.delivery {
    width: 100%;
  }

  .card {
    padding: 20px 30px;
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .modal-content {
    height: inherit;
    min-height: 100%;
    border-radius: 0;
  }

  .btn-icon--close {
    position: fixed;
  }

  .product-comment-input {
    max-width: 100%;
  }
}

/* mobile landscape */
@media (min-width: 576px) and (max-width: 767.98px) {
  .top-nav {
    height: 40px;
  }
  .product h2 {
    font-size: 23px;
  }
  .product-price,
  .product-desc,
  .product-packaged {
    font-size: 0.7rem;
  }
  .header-wrap.top-bar-open + .aw-main .product-top.fixed {
    top: 0px;
  }
  .header-wrap.top-bar-open + .aw-main {
    padding-top: 105px;
  }
  .btn-icon--close {
    /* position: fixed; */
  }
}

/* non-mobile */
@media (min-width: 577px) {
  .similar-products-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .similar-products-container .product-thumbnail {
    flex: 0 0 auto;
  }
}

.top-bar {
  margin: 0;
  padding: 5px 0;
  text-align: center;
  background: #ffffff;
  font-family: 'Sofia Pro', sans-serif;
  cursor: pointer;
}

.top-bar .container {
  padding: 0 15px;
  position: relative;
}

.close-top-bar {
  border: none;
  background: none;
  z-index: 10;
}

.tnc a strong,
.tnc a:hover {
  color: #cecece;
  text-decoration: none;
}

.modal-body {
  min-height: 200px;
  padding: 3rem 0;
  overflow-y: auto;
  -webkit-transition: height 1s;
  transition: height 1s;
}

.modal-body-no-footer {
  max-height: calc(100vh - 200px);
}

@media (max-width: 576px) {
  .modal-body,
  .modal-body-no-footer {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    padding-top: 4rem;
  }

  .modal-dialog.modal-lg {
    margin: 0;
  }
}

.delivery-time-modal {
  overflow: visible;
}

.btn-cart-mobile {
  position: fixed;
  bottom: 20px;
  width: 96%;
  left: 2%;
  padding: 20px;
  margin: 0 auto;
  color: #fff;
  background-color: #97adff;
  cursor: pointer;
  display: inline-block;
}

.btn-cart-mobile span {
  position: absolute;
  left: 6%;
}

.cart-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 700;
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  transition: 0.27s all;
}

.cart-mobile .btn-close-cart {
  display: inline-flex;
}

.cart-mobile.open {
  visibility: visible;
  opacity: 1;
}

.close-icon {
  background-image: url('../images/close-black.svg');
  width: 30px;
  height: 30px;
  margin: 20px;
}

.catsearch-icon {
  background-image: url(../images/catsearch.png);
  width: 36px;
  height: 23px;
  background-size: 32px 22px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 10px;
}

.close-icon-grey {
  background-image: url('../images/close-grey.svg');
  width: 20px;
  height: 20px;
}

.btn.btn-checkout-mobile {
  width: 90%;
  margin: 5px auto;
}

.tbl-cart-mobile {
  overflow: auto;
  width: 100%;
  padding: 10px 7%;
  border-top: 0.5px solid #cbcbcb;
  border-bottom: 0.5px solid #cbcbcb;
}

.tbl-cart-mobile table {
  width: 100%;
}

.tbl-cart-mobile tr {
  border-bottom: 0.5px solid #cbcbcb;
}

.tbl-cart-mobile tr:last-child {
  border-bottom: none;
}

.tbl-cart-mobile td {
  padding: 20px 0;
  vertical-align: top;
}

.tbl-cart-mobile td span {
  color: #cbcbcb;
  line-height: 24px;
}

.category-mobile {
  font-weight: 700;
  z-index: 2000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  transition: 0.27s all;
  overflow-y: auto;
  overflow-x: hidden;
}

.category-mobile.open {
  visibility: visible;
  opacity: 1;
}

.category-mobile-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 2rem;
}

.category-mobile-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
.category-mobile-wrap ul li a {
  color: #868686;
}

/* .category-mobile-wrap li {
} */

.category-mobile-wrap li:hover > div {
  border-left: 3px solid #000;
}

.category-mobile-wrap li a {
  color: #000;
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #ececec;
}
.w-90 {
  width: 90% !important;
}

.big-arrow {
  background: url(../images/chevron-right.svg) no-repeat;
  display: block;
  position: absolute;
  right: -52px;
  top: 40%;
  width: 50px;
  background-size: 100% 100%;
  height: 30px;
  border: 0;
  border-radius: 0;
  outline: none;
}
.big-arrow.right-arrow {
  transform: rotate(180deg);
  left: -55px;
}
.big-arrow:hover,
.big-arrow:active,
.big-arrow:focus,
.big-arrow:focus:active {
  border: 0 !important;
  background-color: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
}
.product .row {
  position: relative;
}

.fa.bar-icon {
  height: 35px;
  display: block;
  font-size: 22px;
  text-align: center;
  padding-top: 7px;
}

.dropdown-menu.dropdown-large {
  width: 500px;
  max-height: calc(100vh - 200px);
}

.blog-item {
  height: 400px;
  border-bottom: 3px solid #ebebeb;
}
.blog-item .col:first-child {
  max-height: 100%; /* prevents images from overflowing the column */
  overflow: hidden;
}
.blog-item.blog-post {
  height: auto;
}
.blog-item.blog-post .img-fluid {
  margin-bottom: 1rem;
  float: left;
  margin-right: 3rem;
}

.btn-main.blog-get-started {
  background-color: #78c98d;
  margin-bottom: 30px;
  max-width: 250px;
}

@media (min-width: 576px) {
  .blog-item.blog-post .img-fluid {
    width: calc(50vw - 4rem);
  }
}

@media (max-width: 575.98px) {
  .blog-item.blog-post .img-fluid {
    width: 100%;
  }
}

.blog-date {
  color: #6e8692;
}
.blog-item a.readmore,
.readmore {
  font-size: 16px;
  text-decoration: none;
  color: #6060a8;
  position: relative;
  font-weight: 700;
}
.blog-item h2 > a {
  color: inherit;
}

.blog-date {
  color: #6e8692;
  font-family: 'Aleo', sans-serif;
  font-size: 15px;
  margin-bottom: 20px;
}
.readmore {
  margin-bottom: 0;
  font-size: 16px;
  position: relative;
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 700;
  text-decoration: none;
}
.readmore a {
  color: #6060a8;
}
.readmore i {
  font-size: 15px;
  position: relative;
  top: 0px;
  margin-left: 5px;
}
.readmore:hover,
.readmore:focus {
  text-decoration: none;
  color: #2c357e;
}

.readmore::after {
  position: absolute;
  content: '';
  border-top: 2px solid #6060a8;
  left: 0;
  right: 0;
  bottom: -5px;
}

.dropdown-toggle.disabled::after {
  opacity: 0;
}

.footer-message {
  position: absolute;
  left: 80%;
  top: 0;
  width: 100%;
}

.account-dropdown .dropdown-item a,
.profile-dropdown a {
  color: #263a52 !important;
}

.scroller {
  max-height: calc(50vh - 139px);
  margin-bottom: 8px;
  min-height: calc(50vh - 139px);
  overflow: scroll;
}

.btn-explore {
  background-color: #97adff;
  border-radius: 12px;
  border: 0;
}

@media screen and (min-width: 800px) {
  .aw-header.admin-navbar {
    padding-bottom: 0;
  }
  .admin-navbar .container {
    padding-top: 0;
  }
  .admin-backdrop {
    top: -7px !important;
  }

  .admin-navbar .account-dropdown .dropdown-menu {
    margin-top: 8px;
  }
}

.cart-item .input-group-text {
  padding: 10px 20px;
}

.feedback-modal textarea {
  height: 150px;
}
.feedback-modal .btn {
  width: 100%;
}

.feedback-modal .feedback-btn {
  display: block;
  padding: 0;
  border: 0;
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
}

.feedback-modal .feedback-btn.active {
  background-color: #78c98d !important;
}

.feedback-caption {
  text-align: center;
  margin: 5px -5px;
}

.amount-btn-group {
  width: 100%;
}

.amount-btn {
  border: 2px solid #ced4da;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  border-radius: 10px;
}

.amount-btn.selected {
  background-color: #6c757d;
  color: #fff;
}

.checkout-tips {
  padding: 10px 0;
}
.purple-apply-btn {
  color: #6060a8;
}
.purple-apply-btn.grey-btn {
  color: #888;
}

.delivery-notes {
  margin-bottom: 30px;
}

.delivery-notes .card-body {
  border: 1px solid #ececec;
}

.delivery-notes textarea {
  resize: none;
  padding: 10px;
  height: 150px;
}

.delivery-notes textarea:focus {
  border: 2px solid #78c98d;
}

.product-custom-value {
  height: calc(2.25rem + 2px);
}

.category-card-link,
.category-card-link:hover {
  text-decoration: none;
  color: #000;
}

.category-card-name {
  font-size: 14px;
  font-weight: bold;
  padding: 7px 5px;
  height: 60px;
}

.category-card {
  margin-bottom: 15px;
}

.category-card:hover {
  box-shadow: 0 2px 10px 0 hsla(0, 0%, 58.8%, 0.75);
}

.category-card-img-block {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #eee;
}

.category-card-img-block:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.category-card-img {
  position: absolute;
  width: auto !important;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #898f87;
}

span.category-card-img {
  background-color: #898f87;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.howitworks img {
  max-width: 100%;
  height: auto;
}

.button-custom-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
}

.button-custom-group .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin: 0 3px;
  padding: 15px 10px 13px;
  border-radius: 5px;
}

.package-type-group {
  margin: 0 -2px 7px;
  flex-flow: wrap;
}

.package-type-group .btn {
  font-size: 15px;
  width: auto;
  margin: 0 3px 3px;
}

.backdrop {
  position: fixed;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 2;
}

.backdrop-missing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 2;
}

.missing-modal {
  background: #fff;
  border-radius: 5px;
  max-width: 200px;
  min-height: 100px;
  margin: 25% auto;
  padding: 30px;
}

.modal-button {
  width: 50%;
  font-size: 18px;
}

.error-modal-button {
  background: #b7b7b7;
}

.error-code {
  cursor: pointer;
}

.error-modal {
  max-width: 600px;
  min-height: 200px;
  margin: 0 auto;
}

.courier-modal {
  max-width: 600px;
  min-height: 200px;
  margin: 120px auto;
}

@media (max-width: 700px) {
  .error-modal {
    width: 320px;
    margin: 0 -20px;
  }

  .react-switch-bg {
    height: 26px !important;
    width: 43px !important;
  }

  .react-switch-handle {
    height: 23px !important;
    width: 15px !important;
  }

  th[class^='MuiTableCell-root'],
  th[class*=' MuiTableCell-root'],
  td[class^='MuiTableCell-root'],
  td[class*=' MuiTableCell-root'] {
    padding: 10px 15px !important;
    font-size: 10px !important;
    text-align: center !important;
  }

  td[class^='MuiTableCell-root'] .form-inline,
  td[class*=' MuiTableCell-root'] .form-inline {
    justify-content: center;
  }

  td[class^='MuiTableCell-root'] .form-inline .form-check,
  td[class*=' MuiTableCell-root'] .form-inline .form-check {
    width: auto;
  }

  td[class^='MuiTableCell-alignLeft'],
  td[class*=' MuiTableCell-alignLeft'] {
    padding-left: 12px !important;
    text-align: left !important;
  }
}

.error-header {
  padding: 13px;
}
.error .error-submit {
  margin: 2% 50%;
}

.scrollable-table {
  width: 100%;
}

.courier-page {
  margin-top: 60px;
}

.paper-button {
  text-transform: none;
}

.product-selection-container {
  padding: 2em;
  margin: auto;
  height: 60vh;
  width: 50vw;
}
.product-selection-button {
  display: inline-block;
  width: 40%;
  padding: 1.75em;
  margin-top: -5%;
}

.product-selection-download {
  text-transform: none;
  width: 100%;
  text-align: center;
  height: 30%;
  padding: 0.5em;
}

.product-selection-upload {
  width: 100%;
  height: 70%;
  text-align: center;
  padding: 1em;
  margin-top: -2%;
}

.product-selection-container-inner {
  background-color: '#ffffff';
  height: 100%;
  width: 100%;
  border-radius: 2%;
}

.product-selection-upload-image-button {
  margin: auto;
  text-align: center;
  width: 40%;
  margin-top: -2%;
}

.product-selection-input-file {
  display: block;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.clickable-row:active {
  background-color: #eee;
}

@media (min-width: 1200px) {
  .clickable-row:hover,
  .clickable-row:active {
    background-color: #eee;
  }
}

.sku-modal {
  max-width: 900px;
  margin: 0 auto;
}
.qr-modal-control {
  background: #fff;
  position: absolute;
  width: 100%;
  top: -39px;
  text-align: right;
}
.qr-modal-control .btn-icon--close {
  top: 0;
  right: 0;
  position: relative;
}
.qr-modal-backdrop {
  background: rgba(0, 0, 0, 0.95);
  top: 0;
  z-index: 9999;
}
.qr-modal-result {
  word-break: break-all;
}

@media (max-width: 600px) {
  .sku-modal-first-col {
    width: 135px;
  }
}

.co-packing-page {
  margin-top: -25px;
}

.waitinglist-modal {
  background: rgb(215, 232, 245);
  background: linear-gradient(
    180deg,
    rgba(215, 232, 245, 1) 0%,
    rgba(249, 251, 253, 1) 100%
  );
  padding: 30px;
}
.waitinglist-content {
  text-align: center;
}
.waitinglist-content .fc-size-lg .dcc {
  height: 140px !important;
  width: 80px !important;
  font-size: 140px !important;
}
.waitinglist-content .fc-size-md .dcc {
  height: 100px !important;
  width: 56px !important;
  font-size: 100px !important;
}
.waitinglist-content .fc-size-lg .dcc .tile {
  height: 70px !important;
  width: 80px !important;
}
.waitinglist-content .fc-size-md .dcc .tile {
  height: 50px !important;
  width: 56px !important;
}
.waitinglist-content .dcc .tile.front {
  background-color: #3c2dbf !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.waitinglist-content .dcc .tile.back {
  background: rgb(108, 85, 246) !important;
  background: linear-gradient(
    180deg,
    rgba(108, 85, 246, 1) 0%,
    rgba(75, 57, 244, 1) 28%
  ) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.waitinglist-content .dcc .hinge {
  border-bottom: none !important;
  box-shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.7) !important;
}
.waitinglist-content .fc-size-lg .dcc .hinge {
  width: 80px !important;
  top: 70px !important;
}
.waitinglist-content .fc-size-md .dcc .hinge {
  width: 55px !important;
  top: 50px !important;
}
.waitinglist-content .fc-size-lg .dcc .digit {
  height: 70px !important;
}
.waitinglist-content .fc-size-md .dcc .digit {
  height: 50px !important;
}
.waitinglist-content .fc-size-lg .dcc .back .digit {
  padding-bottom: 70px !important;
}
.waitinglist-content .fc-size-md .dcc .back .digit {
  padding-bottom: 50px !important;
}
#waitingListCounter {
  display: inline-block;
}
#waitingListCounter * {
  box-sizing: content-box;
}
.waitinglist-link {
  font-weight: bold;
  font-size: 14px;
  border: 0;
  background: transparent;
  text-align: center;
  width: 100%;
  outline: none;
}
.waitinglist-copy {
  background: #6060a8;
  color: #fff;
  padding: 7px 15px;
  margin: 7px 0;
}

.pin-input {
  position: relative;
}
.pin-input-ok {
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 20px !important;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-family: 'Sofia Pro';
  margin-bottom: 15px;
  transition: 300ms all;
  max-height: 300px;
  overflow: hidden;
}
.filters-title {
  text-transform: uppercase;
  font-weight: bold;
}
.filters-values ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  font-weight: normal;
}
.filters-values ul li {
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}
.filters-values ul li:before {
  content: '';
  display: block;
  width: 13px;
  height: 13px;
  border: 1px solid #000;
  margin-right: 7px;
  margin-top: -3px;
}
.filters-values ul li.filters-selected:before {
  background-color: #97adff;
}
.filters-values ul li.active {
  font-weight: bold;
  color: #6060a8;
}
.filters.vertical,
.filters.column {
  display: block;
  line-height: 1.5;
}
.filters.vertical .filters-values ul,
.filters.vertical {
  flex-direction: column;
}
.filters.vertical .filters-values ul {
  align-items: flex-start;
}
.filters.vertical .filters-values ul li {
  margin-left: 0;
}
.filters.column .filters-values ul li {
  width: 40%;
}
.category-mobile .filters {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.category-mobile .filters-values {
  margin-left: 30px;
  margin-top: 10px;
}

.filters-values.as-sort ul li:before {
  content: none;
}
.filters-values.as-sort ul li:not(:last-child):after {
  content: '|';
  margin-left: 15px;
}

.carbon-bar-info {
  max-width: 100%;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: normal;
}
.carbon-bar-info .package-info-popover {
  z-index: 10;
  left: 50%;
  top: 25px;
}
.carbon-bar-info .package-info-popover:after,
.carbon-bar-info .package-info-popover:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: auto;
}
.carbon-bar-info .package-info-popover:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.carbon-bar-info .package-info-popover:before {
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #dddddd;
  border-width: 11px;
  margin-left: -11px;
}
.carbon-bar {
  height: 15px;
  background: #eee;
  max-width: 350px;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 5px;
}
.carbon-bar-value {
  background: #97adff;
  height: inherit;
}
.categories-list-group {
  cursor: pointer;
}
.categories-list-group:hover {
  background: #eee;
}
/* .categories-list-group + ul {
  display: none;
}
.categories-list-group.selected-group + ul {
  display: block;
} */

#nav-hero {
  padding-top: 5px;
}
.color-purple {
  color: #97adff;
}

.scroll-text {
  overflow: hidden;
  white-space: nowrap;
}

.scroll-text h1 {
  text-align: center;
  font-family: 'NEONGLOW';
  color: #3c2ebf;
}

/* Move it (define the animation) */
/*
@-moz-keyframes scroll-right {
  0%   { -moz-transform: translateX(-100%); }
  100% { -moz-transform: translateX(100%); }
}
@-webkit-keyframes scroll-right {
  0%   { -webkit-transform: translateX(-100%); }
  100% { -webkit-transform: translateX(100%); }
}
@keyframes scroll-right {
  0%   {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
} */

#div-1-scroll {
  display: inline-block;
  animation: marquee 20s linear infinite;
}

#div-2-scroll {
  display: inline-block;
  animation: marquee2 20s linear infinite;
  /* Must be half the animation duration of both divs so it stats in sync to fill void left by completed transtition of first div */
  animation-delay: 10s;
}

@keyframes marquee {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0%);
  }
}
