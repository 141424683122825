.url {
  text-overflow: ellipsis;
  overflow: hidden;
}

.pseudoInputContainer {
  height: 40vh;
  overflow: auto;
  padding: 0.5rem;
  box-shadow: 1px 1px 4px 0px #bbb inset;
  background-color: #fafafa;
}

.form {
  flex: 1;
}
